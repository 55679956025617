import React from 'react';
import styles from './guidance.module.scss';

type Props = {};

const Methodology = (props: Props) => {
  return (
    <div className={styles.methodology}>
      <p>
        RedFlag Accelerator Typologies has been created in a three-stage
        process:
      </p>
      <ul className={styles.typologyStages}>
        <li>
          Research: to summarise and analyse numerous leading publications
          (reports, studies, etc.) on financial indicators of Human trafficking
        </li>
        <li>
          Analysis: to synthesise and condense financial indicators, typologies
          and patterns into a single reference.
        </li>
        <li>
          Enhancement: to enrich the red flags with additional attributes,
          including indicator type, strength, aggravating risk factors, data
          type, data locations, data points and reference data.
        </li>
      </ul>

      <p>
        In the RedFlag Accelerator Typologies publications we are using the
        following terminology:
      </p>
      <ul className={styles.terminology}>
        <li>
          Typology - a combination of scenarios in a general group defining a
          particular class of criminality (e.g., sex trafficking, forced labour,
          child sexual exploitation etc.) that are sufficiently homogeneous to
          be treated as a type.
        </li>
        <li>
          Scenario - a combination of red flags revealing a repeated
          persona-based pattern of elements (e.g., characteristics, behaviours
          etc.) in data that is matching a typology.
        </li>
        <li>
          Flag - an indicator of any undesirable characteristic or behaviour
          that can be detected in data, suggesting that there is a potential
          threat relating to criminal activity.
          <p>
            Each red flag should be considered individually as a suspicious
            activity indicator requiring further review. However, this approach
            would likely give rise to many false positives, particularly where
            the red flag is ranked medium or weak. A more efficient approach is
            to look out for the combinations of red flags within scenarios,
            which aggregate strength scores across the same accounts and/or
            customers in a relevant context.
          </p>
          <p>
            To ensure the application through different client bases, services
            and jurisdictions, red flags are classified by the customer type,
            activity type and further broken down by the origin and type of
            data, as well as types of behaviours and the actor type involved.
          </p>
        </li>
        <li>
          High-risk Businesses and Territories - certain industries, business
          types and countries are exposed to higher human trafficking risk.
          Listed high-risk businesses and territories are illustrative examples
          only. Each firm must examine their own business model, customer base
          and jurisdictions in order to identify the full extent and nature of
          their risk profile. As mandated by the anti-money laundering
          regulations in many jurisdictions around the world, an adequate and
          regular risk assessment is the only way to comprehensively identify
          where the human trafficking risks lie for a particular company.
        </li>
      </ul>
    </div>
  );
};

export default Methodology;
