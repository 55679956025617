import React from 'react';
import styles from './graphGuide.module.scss';

type Props = {};

const Guide = (props: Props) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Graph Guide</h2>
      <ul className={styles.list}>
        <li>
          <h3 className={styles.subHeading}>Overiew</h3>
          <p>
            RedFlag Accelerator Typologies Portal offers a graphical view of the
            typologies, scenarios and red flags related to trafficking and child
            sexual exploitation. Hierarchical structure of a graph enables to
            explore crimes starting from:
          </p>
          <ul className={styles.listOverview}>
            <li>
              the broadest typology level defining high-level classes of
              criminality
            </li>
            <li>
              through persona-based scenarios revealing various actor involved
              in criminal activity
            </li>
            <li>
              up to the lowest level of red flags describing suspicious
              activities in the scenario context, divided by Customer Type
              (Retail or Business Banking)
            </li>
          </ul>
        </li>
        <li>
          <h3 className={styles.subHeading}>How to navigate?</h3>
          <ul className={styles.listNavigate}>
            <li>
              Double-click on a node to expands the node and show the associated
              elements (i.e., typology &rarr; scenarios &rarr; red flags or red
              flag &rarr; associated scenarios)
            </li>
            <li>Hover over a node to display an additional description</li>
            <li>
              Use the labels in the legend to filter down certain types of nodes
              (i.e., Retail or Business Banking Red Flags)
            </li>
            <li>
              Right-click on the node to hide / collapse / focus / pin a node
            </li>
            <li>
              Use the buttons in the bottom left corner to zoom-in or zoom-out /
              turn on fit to screen mode / rearrange nodes back to hierarchical
              layout / lock nodes / undo / turn on full-screen mode
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Guide;
