import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import './filterStyling.scss';

type OwnProps = {
  data: string[];
  filterLabel: string;
  param: string;
};

const RedFlagFilter = ({ data, filterLabel, param }: OwnProps) => {
  const [filterValue, setFilterValue] = useState<string[] | undefined>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!searchParams.get(param)) return;

    setFilterValue(searchParams.get(param)?.split(','));
  }, [location.search]);

  const handleChange = (e: any) => {
    setFilterValue(e.target.value);

    e.target.value.length > 0
      ? searchParams.set(param, e.target.value)
      : searchParams.delete(param);

    navigate({
      search: `?${searchParams.toString()}`,
    });
  };

  return (
    <div className={'redFlag-filter'}>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-name-label">{filterLabel}</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={filterValue}
          onChange={handleChange}
          input={<OutlinedInput label="Name" />}
        >
          {data.map((entry: string) => (
            <MenuItem key={entry} value={entry}>
              {entry}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default RedFlagFilter;
