import { useNavigate } from 'react-router-dom';
import Hero from 'components/Hero';
import { ReactComponent as IconAlert } from 'assets/icons/icon-alert.svg';
import { ReactComponent as IconNews } from 'assets/icons/icon-news.svg';
import { ReactComponent as IconExpert } from 'assets/icons/icon-profile.svg';
import typologiesHomepage from 'assets/typologies-homepage.png';
import styles from './home.module.scss';

type Props = {};

const Home = (props: Props) => {
  const navigate = useNavigate();

  return (
    <main className={styles.content}>
      <Hero
        user="User"
        title="Welcome to the RedFlag Accelerator Portal"
        description="Human Trafficking (including labor and sex trafficking and
          exploitation) and Child Sexual Exploitation (including CSAM and
          grooming)"
      />
      <section className={styles.categories}>
        <div
          className={styles.categories__left}
          onClick={() => navigate('/typology-landing')}
        >
          <img src={typologiesHomepage} alt="" />
          <h4 className={`${styles.heading} ${styles.typologies}`}>
            Typologies
            <p className={styles.typologies}>
              Accelerate the search by accessing the most comprehensive set of
              global human trafficking red flags in context.
            </p>
          </h4>
        </div>
        <div className={styles.categories__right}>
          <div
            className={`${styles.categories__right__sub} ${styles.categories__right__featured}`}
          >
            <h4
              className={`${styles.heading} ${styles.featured}`}
              onClick={() => navigate('/typologies/typology=8000')}
            >
              <IconAlert /> Alert
              <span>
                New Elderly Financial Exploitation typology now available
              </span>
            </h4>
          </div>
          <div
            className={styles.categories__right__sub}
            onClick={() => navigate('/events')}
          >
            <h4 className={styles.heading}>
              <IconNews /> News & Events
            </h4>
          </div>
          <div
            className={styles.categories__right__sub}
            onClick={() => navigate('/expert')}
          >
            <h4 className={styles.heading}>
              <IconExpert /> Ask the Expert
            </h4>
          </div>
          <div
            className={`${styles.categories__right__sub} ${styles.categories__right__iFeed}`}
          >
            <h4 className={`${styles.heading} ${styles.soon}`}>
              Intelligence Feed <p>COMING SOON</p>
            </h4>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
