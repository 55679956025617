// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aboutUs_pageHeader__5Ur3c {\n  display: flex;\n  flex-direction: column;\n  padding: 2rem 2rem 0;\n}\n\n.aboutUs_heading__AMIZ- {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  font-family: var(--font-display);\n}\n.aboutUs_heading__AMIZ- span {\n  margin: 0 2rem;\n  height: 3rem;\n  width: 1px;\n  background-color: var(--icon-color);\n}\n.aboutUs_heading__selection__63SVP {\n  font-size: 2.4rem;\n  color: var(--color-red);\n  cursor: pointer;\n}\n.aboutUs_heading__selection__63SVP:hover {\n  filter: brightness(1.1);\n}\n.aboutUs_heading__selection--active__fOhEb {\n  font-size: 3.4rem;\n  color: var(--text-heading);\n  cursor: auto;\n}", "",{"version":3,"sources":["webpack://./src/pages/AboutUs/aboutUs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gCAAA;AACF;AACE;EACE,cAAA;EACA,YAAA;EACA,UAAA;EACA,mCAAA;AACJ;AAEE;EACE,iBAAA;EACA,uBAAA;EACA,eAAA;AAAJ;AAEI;EACE,uBAAA;AAAN;AAGI;EACE,iBAAA;EACA,0BAAA;EACA,YAAA;AADN","sourcesContent":[".pageHeader {\n  display: flex;\n  flex-direction: column;\n  padding: 2rem 2rem 0;\n}\n\n.heading {\n  display: flex;\n  align-items: center;\n  margin-bottom: 2rem;\n  font-family: var(--font-display);\n\n  & span {\n    margin: 0 2rem;\n    height: 3rem;\n    width: 1px;\n    background-color: var(--icon-color);\n  }\n\n  &__selection {\n    font-size: 2.4rem;\n    color: var(--color-red);\n    cursor: pointer;\n\n    &:hover {\n      filter: brightness(1.1);\n    }\n\n    &--active {\n      font-size: 3.4rem;\n      color: var(--text-heading);\n      cursor: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "aboutUs_pageHeader__5Ur3c",
	"heading": "aboutUs_heading__AMIZ-",
	"heading__selection": "aboutUs_heading__selection__63SVP",
	"heading__selection--active": "aboutUs_heading__selection--active__fOhEb"
};
export default ___CSS_LOADER_EXPORT___;
