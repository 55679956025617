import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styles from './breadcrumbs.module.scss';
import { TypologySummaryContext } from '../../pages/Typologies/Typologies';

interface CrumbEntry {
  title: string;
  url: string;
}

type Props = {
  crumbData: CrumbEntry[];
};

const Breadcrumbs = ({ crumbData }: Props) => {
  const summaryHandler = useContext(TypologySummaryContext);

  return (
    <ul className={styles.list}>
      <li className={styles.item} onClick={summaryHandler}>
        <Link className={styles.link} to="/typologies">
          Overview
        </Link>
        <span>&rarr;</span>
      </li>
      {crumbData.map((el, i, arr) => (
        <li key={i} className={styles.item}>
          <Link className={styles.link} to={el.url}>
            {el.title}
          </Link>
          {i !== arr.length - 1 ? <span>&rarr;</span> : null}
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumbs;
