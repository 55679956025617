import { useState } from 'react';
//import { ReactComponent as IconAlert } from 'assets/icons/icon-alert.svg';
import styles from './highRiskOverview.module.scss';
import Overview from './Overview';
import Metholody from './Metholody';
import DataSources from './DataSources';

type Props = {};

const HighRiskOverview = (props: Props) => {
  //3 tabs: 'overview', 'methodology', 'data'
  const [showTab, setShowTab] = useState<'overview' | 'methodology' | 'data'>(
    'overview'
  );
  return (
    <section className={styles.container}>
      {/*<div className={styles.whatsNew} onClick={() => console.log('whats new')}>
        <IconAlert />
        <p>What's New</p>
      </div>*/}

      <div className={styles.content}>
        <div className={styles.description}>
          {showTab === 'overview' && <Overview />}
          {showTab === 'methodology' && <Metholody />}
          {showTab === 'data' && <DataSources />}
        </div>
        <ul className={styles.tabs}>
          <li
            className={`${styles.tab} ${
              showTab === 'overview' ? styles.active : ''
            }`}
            onClick={() => setShowTab('overview')}
          >
            Overview
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'methodology' ? styles.active : ''
            }`}
            onClick={() => setShowTab('methodology')}
          >
            Metholody
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'data' ? styles.active : ''
            }`}
            onClick={() => setShowTab('data')}
          >
            Data Sources
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HighRiskOverview;
