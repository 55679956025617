import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(origin: string, destination: string) {
  return { origin, destination };
}

const rows = [
  createData('Albania', 'Greece / Italy / The Netherlands / United Kingdom'),
  createData('Belarus', 'Poland / Russia'),
  createData('Bolivia', 'Argentina'),
  createData('Bulgaria', 'Italy / The Netherlands / Poland'),
  createData('Cambodia', 'China / Indonesia / Malaysia'),
  createData('China', 'Kazakhstan / United States'),
  createData('Eritrea', 'Egypt / Sudan'),
  createData('Ethiopia', 'Egypt / Kenya / Saudi Arabia'),
  createData('Indonesia', 'Malaysia / Saudi Arabia / UAE'),
  createData('Iran', 'UAE'),
  createData('Kenya', 'Germany / India / Saudi Arabia / UAE'),
  createData('Laos', 'Indonesia / Thailand'),
  createData('Mali', 'Senegal'),
  createData('Mexico', 'United States'),
  createData('Moldova', 'Turkey / Ukraine'),
  createData('Mozambique', 'South Africa'),
  createData('Myanmar', 'Indonesia / Thailand'),
  createData('Nepal', 'India'),
  createData('Nicaragua', 'Guatemala / United States'),
  createData('Nigeria', 'Italy / Morocco / Russia / United Kingdom'),
  createData('Philippines', 'Japan / Saudi Arabia / United States'),
  createData('Romania', 'Italy / The Netherlands / Spain / United Kingdom'),
  createData('Somalia', 'Greece'),
  createData('Thailand', 'Indonesia / South Africa / United States'),
  createData('Uganda', 'China / India / Kenya / Malaysia / Thailand'),
  createData('Ukraine', 'Poland / Russia / Turkey'),
  createData('Vietnam', 'China / Malaysia / United Kingdom'),
  createData('Yemen', 'Oman'),
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={'corridorsTable'}>
        <TableHead>
          <TableRow>
            <TableCell>Countries of Origin</TableCell>
            <TableCell>Countries of Destination</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.origin}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.origin}
              </TableCell>
              <TableCell>{row.destination}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
