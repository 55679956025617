import React from 'react';
import styles from './highRiskOverview.module.scss';
type Props = {};

const Overview = (props: Props) => {
  return (
    <>
      <p className={styles.paragraph}>
        RedFlag Accelerator features non-exhaustive lists of countries and
        corridors that are associated with higher human trafficking risk,
        increasing the strength of any indicator or combination of indicators
        within a scenario.
      </p>
      <p className={styles.paragraph}>
        Human trafficking criminality is not limited to specific countries or
        regions only; however, trafficking and exploitation patterns and volumes
        may vary in different parts of the world. High-risk locations often
        include conflict-affected zones, countries where there is a weak rule of
        law such as the absence of a functioning judiciary or just
        administration of law enforcement agencies, and high corruption. Where
        governments do not fulfil their duty to protect human rights, human
        trafficking risks exponentially increase. Countries where the workforce
        includes a large number of migrant workers, and where third-party
        employment entities are widely used, also present a higher risk of human
        trafficking. The prevalence of corruption and bribery, often measured by
        the perceived levels of corruption in the country’s public sector, can
        also indicate higher human trafficking risks, and forced labour risks in
        particular.
      </p>
      <p className={styles.paragraph}>
        In terms of the sexual exploitation of children, no country is immune,
        while environmental factors can assist in identifying locations at a
        greater risk. A key characteristic of the high-risk online child sexual
        exploitation countries is a combination of an accessible internet, a
        widespread poverty and prevalence of English as a second or third
        language.
      </p>
      <p className={styles.paragraph}>
        Organisations that study trafficking and exploitation patterns tend to
        classify countries as source or origin countries, transit countries or
        destination countries for human trafficking. However, it should be noted
        that this classification system can be misleading because many countries
        may fall under each of these categories at some point. According to the
        UNODC, trafficking flows are dominated less by clear geographic
        boundaries than by relative pockets of economic disparity, with victims
        flowing from poorer to richer areas within countries, regions or across
        the globe.
      </p>
    </>
  );
};

export default Overview;
