import styles from './nav.module.scss';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as IconTypologies } from 'assets/icons/icon-typologies.svg';
import { ReactComponent as IconHighRisk } from 'assets/icons/icon-high-risk.svg';
import { ReactComponent as IconEvents } from 'assets/icons/icon-news.svg';
import { ReactComponent as IconExpert } from 'assets/icons/icon-profile.svg';
import { ReactComponent as IconAbout } from 'assets/icons/icon-world.svg';
import { ReactComponent as IconGlossary } from 'assets/icons/icon-glossary.svg';
import navData from 'data/nav.json';

type Props = {};

const icons: {
  [key: string]: any;
} = {
  typologies: <IconTypologies />,
  events: <IconEvents />,
  expert: <IconExpert />,
  about: <IconAbout />,
  glossary: <IconGlossary />,
  intelligence_feed: <IconHighRisk />,
};

const Nav = (props: Props) => {
  const location = useLocation();

  return (
    <nav aria-label="primary navigation" className={styles.wrapper}>
      <ul className={`${styles.list} ${styles['list--fixed']}`}>
        {navData.map((item) => (
          <li className={styles.list__item} key={item.id}>
            <div
              className={
                location.pathname.includes(item.url)
                  ? `${styles.list__item__wrapper} ${styles['list__item__wrapper--active']}`
                  : styles.list__item__wrapper
              }
            >
              <Link to={item.url} className={styles.list__item__link}>
                {item.text}
                {icons[item.id]}
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Nav;
