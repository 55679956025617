import HighRiskBusinessesTable from '../HighRiskBusinessesTable/HighRiskBusinessesTable';
import styles from './businesses.module.scss';
import highRiskBusinessData from 'data/high-risk-businesses.json';

type Props = {};

const Businesses = (props: Props) => {
  return (
    <section className={styles.content}>
      <article>
        <div className={styles.overview}>
          <p className={styles.paragraph}>
            The RedFlag Accelerator Typologies set of non-exhaustive business
            types and activities can be associated with higher human trafficking
            and exploitation risk, increasing the strength of any red flag or
            combination of red flags within a scenario.
          </p>
          <p className={styles.paragraph}>
            The level of risk depends on a range of intersecting factors such as
            company's business model, the social, political and environmental
            conditions in the countries within which it operates, and others.
            Some industries may be at greater risk than others, for example
            those that rely on cheap or seasonal labour or involve difficult and
            dangerous jobs pose increased risk for labour exploitation, while
            those involved in hospitality or adult entertainment may have
            greater sexual exploitation risk.
          </p>
          <ul className={styles.list}>
            <h4>Other higher risk factors include:</h4>
            <li className={styles.item}>
              unusual or excessively complex business ownership structures;
            </li>
            <li className={styles.item}>
              business models that are structured around high-risk work
              practices;
            </li>
            <li className={styles.item}>
              businesses located in high-risk geographical areas;
            </li>
            <li className={styles.item}>
              businesses requesting products or services that might favour
              anonymity, in particular, online;
            </li>
            <li className={styles.item}>businesses with access to children.</li>
          </ul>
          <p className={styles.paragraph}>
            Ultimately, the highest level of human trafficking and exploitation
            risks exists at the intersection of each of these risk factors
            above. One or more risk factors compound to elevate the likelihood
            of risk. For example, the risk associated with vulnerable
            populations is exacerbated by the limited regulation of labour
            practices in high-risk business models (such as the use of
            recruitment companies and seasonal work).
          </p>
          <p className={styles.paragraph}>
            In relation to laundering the proceeds generated by human
            trafficking and exploitation, most commonly, the higher risk
            situations are encountered with cash-intensive businesses, or with
            firms providing a recruitment, casting, payroll or accounting
            services. The higher risk for such businesses arises from the
            likelihood for income to be undeclared, fictitious businesses and
            falsification of the information, involvement of professional money
            launderers and the mixing of legitimate proceeds with illegitimate
            proceeds.
          </p>
        </div>
      </article>
      <div className={styles.highRiskTable}>
        <h2>Business Types/ Activities</h2>
        <HighRiskBusinessesTable data={highRiskBusinessData} />
      </div>
    </section>
  );
};

export default Businesses;
