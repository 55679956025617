import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import ScenarioList from 'components/ScenarioList';
import { createMarkup } from 'helpers/createMarkup';
import { useFetchData } from 'hooks/useFetchData';
import styles from '../typologies.module.scss';

interface IndicatorType {
  id: number;
  name: string;
  description: string;
  strength: string;
  url: string;
}

interface FactorType {
  id: number;
  name: string;
  role: string;
}

interface FactorGroupType {
  id: number;
  type: string;
  factors: FactorType[];
}

type Props = {
  clickedIndicator: IndicatorType;
};

const Indicator = ({ clickedIndicator }: Props) => {
  const { typologyId, scenarioId, indicatorId } = useParams();

  const { data: dataFactors } = useFetchData(
    `${process.env.REACT_APP_API_BASE_URL!}indicators/${clickedIndicator.id}/`,
    process.env.REACT_APP_API_TOKEN!
  );

  const crumbData = [
    {
      title: `Typology ${typologyId}`,
      url: `/typologies/typology=${typologyId}`,
    },
    {
      title: `Scenario ${scenarioId}`,
      url: `/typologies/typology=${typologyId}/scenario=${scenarioId}`,
    },
    {
      title: `Indicator ${indicatorId}`,
      url: '#',
    },
  ];

  return clickedIndicator ? (
    <section className={styles.typology__wrapper}>
      <Breadcrumbs crumbData={crumbData} />
      <h2>{clickedIndicator.name}</h2>
      <h3>Description</h3>
      <div className={styles.typology__content}>
        <div
          className={styles.typology__content__description}
          style={{ height: 'auto' }}
          dangerouslySetInnerHTML={createMarkup(clickedIndicator.description)}
        ></div>
        {dataFactors?.factor_groups.length > 0 && (
          <>
            <h2>Data Factors</h2>
            {dataFactors?.factor_groups.map((el: FactorGroupType) => (
              <ScenarioList
                key={el.id}
                title={`Factor ${el.id}`}
                typologyData={[]}
                scenarioListData={el.factors}
                selectionMode={false}
                selectionList={[]}
                scenarioClickHandler={() => {}}
              />
            ))}
          </>
        )}
      </div>
    </section>
  ) : null;
};

export default Indicator;
