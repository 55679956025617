import React from 'react';
import styles from './categories.module.scss';

type Props = {
  children: React.ReactNode;
  sidebarOpen: boolean;
  gridCount: 2 | 3;
  style?: {};
};

const Categories = ({ children, sidebarOpen, gridCount, style }: Props) => {
  return (
    <section
      style={style}
      className={
        sidebarOpen
          ? `${styles.container} ${styles[`container--${gridCount}`]}`
          : `${styles.container} ${styles[`container--${gridCount}`]} ${
              styles[`container--${gridCount}--full`]
            }`
      }
    >
      {children}
    </section>
  );
};

export default Categories;
