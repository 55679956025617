import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './tableStyling.scss';

function createData(origin: string, destination: string) {
  return { origin, destination };
}

const rows = [
  createData('Balkans', 'Western Europe'),
  createData('Central America and the Caribbean', 'North America'),
  createData('Central and Eastern Europe', 'Western Europe'),
  createData('East Africa and North Africa', 'Gulf / South Asia'),
  createData('East Asia and the Pacific', 'Gulf / North America / Europe'),
  createData('North, West Africa', 'Western, Southern Europe'),
  createData('South America', 'East Asia and the Pacific'),
  createData('South Asia', 'Gulf'),
];

export default function BasicTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={'corridorsTable'}>
        <TableHead>
          <TableRow>
            <TableCell>Regions of Origin</TableCell>
            <TableCell>Regions of Destination</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.origin}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.origin}
              </TableCell>
              <TableCell>{row.destination}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
