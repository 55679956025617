import EventArticle from './EventArticle';
import styles from './events.module.scss';
import Categories from 'components/Categories';
import eventsData from 'data/events.json';

type Props = {};

const Events = (props: Props) => {
  return (
    <main className={'content'}>
      <div className={'menu menu--animate'}>
        <div className={styles.content}>
          <h1 className={styles.heading}>News & Events</h1>
          {/*<div className={styles.filters}>
            <select name="" id="" className={styles.filters__select}>
              <option value="">All Categories</option>
              <option value="">General Inquiry</option>
              <option value="">Financial Crime Related</option>
              <option value="">Portal Help</option>
            </select>
            <div className={styles.filters__calendar}>
              12/03/2022 <IconCalendar />
            </div>
            <label htmlFor="">Sort by</label>
            <select name="" id="" className={styles.filters__select}>
              <option value="">Most Relevant</option>
              <option value="">General Inquiry</option>
              <option value="">Financial Crime Related</option>
              <option value="">Portal Help</option>
            </select>
          </div>*/}
          <Categories gridCount={3} sidebarOpen={false}>
            {eventsData.map((event, i) => (
              <EventArticle data={event} key={i} />
            ))}
          </Categories>
        </div>
      </div>
    </main>
  );
};

export default Events;
