import { useState, useEffect } from 'react';
var base64 = require('base-64');

export const useLoadNeo4jData = (
  url: string,
  username: string,
  password: string
) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchKnowledgeGraph = async () => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: new Headers({
            Authorization: 'Basic ' + base64.encode(`${username}:${password}`),
          }),
        });
        const resData = await res.json();
        setData(resData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchKnowledgeGraph();
  }, [url, username, password]);

  return { data };
};
