import { useNavigate } from 'react-router-dom';
import styles from './dashboardCard.module.scss';

type Props = {
  id: number;
  title: string;
  description?: string;
  url?: string;
};

const DashboardCard = ({ id, title, description, url }: Props) => {
  const navigate = useNavigate();
  return (
    <article
      className={styles.container}
      key={'categories-' + id}
      onClick={url ? () => navigate(url) : () => {}}
    >
      <div className={styles.textBox}>
        <h4
          className={`${styles.textBox__heading} ${
            !description ? styles.center : ''
          }`}
          //style={{ textAlign: description ? 'left' : 'center' }}
        >
          {title}
        </h4>
        {description && (
          <p className={styles.textBox__description}>{description}</p>
        )}
      </div>
    </article>
  );
};

export default DashboardCard;
