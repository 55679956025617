import React, { useState, useContext, useEffect } from 'react';
import styles from './login.module.scss';
import rfaLogo from 'assets/rfa-logo.png';
import { LoginContext } from 'App';
import credentialList from 'data/logins.json';
import md5 from 'md5';

type Props = {
  title: string;
};

const Login = ({ title }: Props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState(false);

  useEffect(() => {
    const errorMessageTimout = setTimeout(() => {
      setError(false);
    }, 2000);

    return () => clearTimeout(errorMessageTimout);
  }, [error]);

  const setLoggedState = useContext(LoginContext);

  const usernameInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const passwordInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const isLoginValid = (): boolean => {
    return credentialList.some(
      (entry) =>
        entry.password === md5(password) && entry.username === md5(username)
    );
  };

  const loginSubmitHandler = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    isLoginValid() ? setLoggedState(true) : setError(true);
  };

  return (
    <div className={styles.wrapper}>
      <h1>{title}</h1>
      <img className={styles.logo} src={rfaLogo} alt="" />
      <form className={styles.form} onSubmit={loginSubmitHandler}>
        <div className={styles.form__input}>
          <input
            type="text"
            name="username"
            id="username"
            required
            onChange={usernameInputHandler}
            value={username}
          />
          <label htmlFor="username">Username</label>
        </div>
        <div className={styles.form__input}>
          <input
            type="password"
            name="password"
            id="password"
            required
            onChange={passwordInputHandler}
            value={password}
          />
          <label htmlFor="password">Password</label>
        </div>
        <button type="submit" className={styles.btn}>
          Submit
        </button>
        <p className={`${styles.error} ${error ? styles.errorActive : ''}`}>
          Invalid credentials...
        </p>
      </form>
    </div>
  );
};

export default Login;
