import React, { useState } from 'react';
import About from './About';
import Guidance from './Guidance';
import styles from './aboutUs.module.scss';

type Props = {};

const AboutUs = (props: Props) => {
  //2 different tab states: 'about', 'guidance'.
  const [content, setContent] = useState<'about' | 'guidance'>('about');
  return (
    <main className="content">
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h3
            className={`${styles.heading__selection} ${
              content === 'about' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'about'
                ? () => {
                    setContent('about');
                  }
                : () => {}
            }
          >
            About
          </h3>
          <span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'guidance' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'guidance'
                ? () => {
                    setContent('guidance');
                  }
                : () => {}
            }
          >
            Guidance
          </h3>
        </div>
      </section>
      {content === 'about' && <About />}
      {content === 'guidance' && <Guidance />}
    </main>
  );
};

export default AboutUs;
