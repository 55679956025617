import React from 'react';
import styles from './about.module.scss';
type Props = {};

const Overview = (props: Props) => {
  return (
    <div className={styles.overview}>
      <p>
        We at RedCompass Labs have been payment experts for over two decades,
        thus we have a good understanding of the money flowing through bank
        systems. During the course of our work, we recognised that some of the
        payments were actually being generated and processed as a result of the
        exploitation of people. This prompted us a couple of years ago to kick
        off The RedFlag Accelerator Typologies project, aiming to help banks
        find activities related to human trafficking in their data.
      </p>
      <p>
        The RedFlag Accelerator Typologies are created to disrupt human
        trafficking systematically by using our payments and financial crime
        expertise to interrupt the money flow and make it impossible to the
        financial sector to buy and sell people. Developing the RedFlag
        Accelerator Typologies was the first step to equip the financial sector
        with the knowledge to fight against human trafficking.
      </p>
      <p>
        The RedFlag Accelerator Portal is the very first interactive portal to
        be developed to this level of detail explicitly for the financial sector
        context. The key purpose of our portal is to contribute to sharing
        expertise across the industry and provide reliable way of keeping up
        with new trends emerging globally.
      </p>
      <p>
        Analysts across the globe are facing the same challenges – spending
        endless hours to gather and translate threat assessments, regulatory
        guidance, and multiple other sources into actionable insights,
        individually. The RedFlag Accelerator Portal brings relief by sharing
        the findings and insights across the industry, saving time, and allowing
        valuable resources in the financial sector to put their focus on making
        the actual difference.
      </p>
    </div>
  );
};

export default Overview;
