// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".highRiskCorridors_content__Z6B7d {\n  padding: 2rem;\n  display: flex;\n  align-items: flex-start;\n  gap: 2rem;\n}\n@media (max-width: 900px) {\n  .highRiskCorridors_content__Z6B7d {\n    flex-direction: column;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/HighRiskTerritories/HighRiskCorridors/highRiskCorridors.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,uBAAA;EACA,SAAA;AACF;AACE;EANF;IAOI,sBAAA;EAEF;AACF","sourcesContent":[".content {\n  padding: 2rem;\n  display: flex;\n  align-items: flex-start;\n  gap: 2rem;\n\n  @media (max-width: 900px) {\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "highRiskCorridors_content__Z6B7d"
};
export default ___CSS_LOADER_EXPORT___;
