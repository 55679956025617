import React from 'react';
import styles from './hero.module.scss';

type Props = {
  user?: string;
  title: string;
  description: string;
};

const Hero = ({ user, title, description }: Props) => {
  return (
    <section className={styles.wrapper}>
      <div className={styles.content}>
        {user && <h3 className={styles.heading__secondary}>Hello {user},</h3>}
        <h1 className={styles.heading__primary}>{title}</h1>
        <p className={styles.description}>{description}</p>
      </div>
    </section>
  );
};

export default Hero;
