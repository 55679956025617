import { useState, useCallback } from 'react';
import countries_of_origin from 'assets/images/maps/countries-of-origin.png';
import countries_of_destination from 'assets/images/maps/countries-of-destination.png';
import countries_of_transit from 'assets/images/maps/countries-of-transit.png';
import domestic_trafficking_countries from 'assets/images/maps/domestic-trafficking.png';
import trafficking_organ_removal from 'assets/images/maps/trafficking-organ-removal.png';
import sex_tourism_destinations from 'assets/images/maps/sex-tourism.png';
import cse_facilitator from 'assets/images/maps/cse-facilitator.png';
import cse_consumer from 'assets/images/maps/cse-consumer.png';
import highRiskCountriesData from 'data/high-risk-countries.json';
import styles from './highRiskCountries.module.scss';

type Props = {};

type ImageSelector = {
  [key: string]: string;
};

const images: ImageSelector = {
  countries_of_origin: countries_of_origin,
  countries_of_destination: countries_of_destination,
  countries_of_transit: countries_of_transit,
  domestic_trafficking_countries: domestic_trafficking_countries,
  trafficking_organ_removal: trafficking_organ_removal,
  sex_tourism_destinations: sex_tourism_destinations,
  cse_facilitator: cse_facilitator,
  cse_consumer: cse_consumer,
};

const HighRiskCountries = (props: Props) => {
  const [category, setCategory] = useState<string>('countries_of_origin');

  const selectionChangeHandler = useCallback((entry) => {
    setCategory(entry.id);
  }, []);

  const [countryList] = highRiskCountriesData
    .filter((entry) => entry.id === category)
    .map((el) => el.countries);

  return (
    <section className={styles.content}>
      <div className={styles.map}>
        <img src={images[category]} alt="" className={styles.map__image} />
        <ul className={styles.map__countries}>
          {countryList.map((country, i) => (
            <li key={i}>{country}</li>
          ))}
        </ul>
      </div>
      <fieldset className={styles.categories}>
        <legend className={styles.categories__heading}>Category</legend>

        {highRiskCountriesData.map((entry) => (
          <label htmlFor={entry.id} key={entry.id}>
            <input
              type="radio"
              id={entry.id}
              name="categories"
              value={entry.id}
              checked={category === entry.id}
              onChange={() => selectionChangeHandler(entry)}
            />
            {entry.label}
          </label>
        ))}
      </fieldset>
    </section>
  );
};

export default HighRiskCountries;
