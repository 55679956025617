import { useState, useCallback } from 'react';

interface IModal {
  modalOpen: boolean;
  handleModalOpen: () => void;
  handleModalClose: () => void;
}

const useModal = (): IModal => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  return {
    modalOpen,
    handleModalOpen,
    handleModalClose,
  };
};

export default useModal;
