import styles from './scenarioList.module.scss';
import { ScenarioItem } from './ScenarioItem';

type Props = {
  title: string;
  scenarioListData: any[];
  typologyData: any[];
  selectionMode: boolean;
  selectionList: number[];
  scenarioClickHandler: Function;
};

const ScenarioList = ({
  scenarioListData,
  title,
  selectionMode,
  selectionList,
  scenarioClickHandler,
}: Props) => {
  return (
    <ul className={styles.list}>
      <h3 className={styles.list__title}>{title}</h3>
      <li
        className={`${styles.item} ${styles['item--heading']} ${
          selectionMode ? styles.selection : ''
        }`}
      >
        <input
          type="checkbox"
          name=""
          id=""
          className={`${styles.item__checkbox} ${styles.hidden} ${
            selectionMode ? styles.selection : ''
          }`}
        />
        <div className={styles.item__entries}>
          <p>ID</p>
          <p>Type</p>
        </div>
      </li>
      {scenarioListData.map((item) => (
        <ScenarioItem
          key={item.id}
          data={item}
          selectionMode={selectionMode}
          selectedItem={selectionList.includes(item.id)}
          scenarioClickHandler={scenarioClickHandler}
        />
      ))}
    </ul>
  );
};

export default ScenarioList;
