import React, { useState } from 'react';
import Methodology from './Methodology';
import Implementation from './Implementation';
import Recommendation from './Recommendation';
import styles from './guidance.module.scss';

type Props = {};

const Guidance = (props: Props) => {
  const [showTab, setShowTab] = useState<
    'methodology' | 'implementation' | 'recommendation'
  >('methodology');
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description}>
          {showTab === 'methodology' && <Methodology />}
          {showTab === 'implementation' && <Implementation />}
          {showTab === 'recommendation' && <Recommendation />}
        </div>
        <ul className={styles.tabs}>
          <li
            className={`${styles.tab} ${
              showTab === 'methodology' ? styles.active : ''
            }`}
            onClick={() => setShowTab('methodology')}
          >
            Methodology
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'implementation' ? styles.active : ''
            }`}
            onClick={() => setShowTab('implementation')}
          >
            Implementation
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'recommendation' ? styles.active : ''
            }`}
            onClick={() => setShowTab('recommendation')}
          >
            Recommendation
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Guidance;
