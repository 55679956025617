import ExportList from 'components/ExportList';
import { ReactComponent as IconExport } from 'assets/icons/icon-export.svg';
import { ReactComponent as IconCircleQuestion } from 'assets/icons/icon-circle-question.svg';
import styles from './typologies.module.scss';
import React from 'react';

type Props = {
  exportScenarioList: any[];
  exportTypologyList: any[];
};

const Export = ({ exportScenarioList, exportTypologyList }: Props) => {
  return (
    <section className={styles.export}>
      <div className={styles.pageContent}>
        <div className={styles.export__help}>
          <p>
            <IconCircleQuestion />
            How to select what to export?
          </p>
          <p>Visit any pages within Typologies, scenarios or RedFlags.</p>
          <p>Tap 'Select' to select the what you'd like to export.</p>
        </div>
        <div className={styles.export__formats}>
          <h3>Format you'd like to export to</h3>
          <div className={styles.export__formats__checkboxContainer}>
            <label
              htmlFor="format-pdf"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-pdf" id="format-pdf" />
              PDF
            </label>
            <label
              htmlFor="format-01"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-01" id="format-01" />
              Format 01
            </label>
            <label
              htmlFor="format-02"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-02" id="format-02" />
              Format 02
            </label>
            <label
              htmlFor="format-03"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-03" id="format-03" />
              Format 03
            </label>
            <label
              htmlFor="format-04"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-04" id="format-04" />
              Format 04
            </label>
            <label
              htmlFor="format-05"
              className={styles.export__formats__checkbox}
            >
              <input type="checkbox" name="format-05" id="format-05" />
              Format 05
            </label>
          </div>
        </div>
        <h3>Your selections</h3>
        <div className={styles.export__selections}>
          <div className={styles.export__selections__container}>
            <h4>Typologies</h4>
            {exportTypologyList &&
              (exportTypologyList.length > 0 ? (
                <ExportList type="typology" data={exportTypologyList} />
              ) : (
                <p className={styles.export__selections__none}>
                  No typologies were selected.
                </p>
              ))}
          </div>
          <div className={styles.export__selections__container}>
            <h4>Scenarios</h4>
            {exportScenarioList &&
              (exportScenarioList.length > 0 ? (
                <ExportList type="scenario" data={exportScenarioList} />
              ) : (
                <p className={styles.export__selections__none}>
                  No scenarios were selected.
                </p>
              ))}
          </div>
        </div>
        <button className={styles.export__btn}>
          <IconExport /> Export
        </button>
      </div>
    </section>
  );
};

export default Export;
