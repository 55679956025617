import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function createData(scenario: string, id: string, indicators: any[]) {
  return {
    scenario,
    id,
    indicators: indicators,
  };
}

interface IRow {
  row: any;
  scenarioClickHandler: Function;
  indicatorClickHandler: Function;
}

function Row({ row, scenarioClickHandler, indicatorClickHandler }: IRow) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        //onClick={() => setOpen(!open)}
      >
        <TableCell
          component="th"
          scope="row"
          onClick={() => scenarioClickHandler(row)}
        >
          {row.scenario}
        </TableCell>
        <TableCell align="right" onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Indicators</TableCell>
                    <TableCell align="right">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.indicators
                    .sort((a: any, b: any) => a.id - b.id)
                    .map(
                      (indicatorRow: {
                        name: string;
                        url: string;
                        id: number | string;
                      }) => {
                        return (
                          <TableRow
                            key={indicatorRow.id}
                            onClick={() => indicatorClickHandler(indicatorRow)}
                          >
                            <TableCell component="th" scope="row">
                              {indicatorRow.name}
                            </TableCell>
                            <TableCell align="right">
                              {indicatorRow.id}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    scenario: PropTypes.string,
    indicators: PropTypes.array,
    id: PropTypes.number,
  }),
};

export default function CollapsibleTable({
  data,
  scenarioClickHandler,
  indicatorClickHandler,
}: any) {
  const rows = [
    ...data.map((el: any) => createData(el.name, el.id, el.indicators)),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className={'typologiesTable'}>
        <TableHead>
          <TableRow>
            <TableCell>Scenarios</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <Row
                key={row.scenario}
                row={row}
                scenarioClickHandler={scenarioClickHandler}
                indicatorClickHandler={indicatorClickHandler}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
