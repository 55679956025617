import React from 'react';
import styles from './highRiskOverview.module.scss';
type Props = {};

const Metholody = (props: Props) => {
  return (
    <>
      <p className={styles.paragraph}>
        RedFlag Accelerator high-risk territories provide the top 40 high-risk
        human trafficking origin, transit and destination countries as
        identified from various leading data sources, limited to the highest
        occurrence of reported cases and estimations found in data. The
        high-risk human trafficking countries by domestic (intra-country)
        trafficking (top 40) are also included. Some countries may fall into
        more than one category.
      </p>
      <p className={styles.paragraph}>
        High-risk territories also provide the destination countries for the
        trafficking with the purpose of organ removal (top 16) and sex tourism
        (top 15), as well as the high-risk online child sexual exploitation
        countries - top 15 facilitator (source) countries and top 15 consumer
        (offender) countries.
      </p>
      <p className={styles.paragraph}>
        In addition, this section provides a further level of detail by linking
        the high-risk regions and jurisdictions into trafficking corridors. We
        are continuously exploring more trends in existing and new trafficking
        corridors and patterns, along with more in-depth analysis of the actors
        and nature of the emerging cases etc.
      </p>
    </>
  );
};

export default Metholody;
