import React from 'react';
import styles from './login.module.scss';
import LoginForm from 'components/Login';

type LoginProps = {};

const Login = (props: LoginProps) => {
  return (
    <main className={styles.wrapper}>
      <LoginForm title="Portal Login" />
    </main>
  );
};

export default Login;
