import {
  createContext,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { Routes, Route } from 'react-router-dom';
import './index.scss';
import Header from './components/Header';
import Nav from './components/Nav';
import Home from './pages/Home/Home';
import TypologiesLanding from './pages/TypologiesLanding/TypologiesLanding';
import Typologies from './pages/Typologies/Typologies';
import Events from './pages/Events/Events';
import Expert from './pages/Expert/Expert';
//import IntelligenceFeed from './pages/IntelligenceFeed/IntelligenceFeed';
import { useFetchData } from './hooks/useFetchData';
import HighRiskTerritories from 'pages/HighRiskTerritories/HighRiskTerritories';
import HighRiskBusinesses from 'pages/HighRiskBusinesses/HighRiskBusinesses';
import RedFlags from 'pages/RedFlags/RedFlags';
import AboutUs from 'pages/AboutUs/AboutUs';
import Login from 'pages/Login/Login';
//import Glossary from 'pages/Glossary/Glossary';

export const LoginContext = createContext((a: boolean): any => {});

function App() {
  const [theme, setTheme] = useState('theme-dark');
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    //html document class name
    document.documentElement.className = theme;
  }, [theme]);

  const themeSwitchHandler = () => {
    theme === 'theme-dark' ? setTheme('theme-light') : setTheme('theme-dark');
  };

  const { data } = useFetchData(
    `${process.env.REACT_APP_API_BASE_URL!}typologies/`,
    process.env.REACT_APP_API_TOKEN!
  );

  return !loggedIn ? (
    <Routes>
      <Route
        path="/*"
        element={
          <LoginContext.Provider
            value={setLoggedIn as Dispatch<SetStateAction<boolean>>}
          >
            <Login />
          </LoginContext.Provider>
        }
      />
    </Routes>
  ) : (
    <>
      <Header themeSwitchHandler={themeSwitchHandler} />
      <div className="panel">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/typology-landing" element={<TypologiesLanding />} />
          <Route
            path="/typologies/*"
            element={<Typologies typologyApiData={data?.results} />}
          />
          <Route path="/events" element={<Events />} />
          <Route path="/expert" element={<Expert />} />
          <Route path="/about" element={<AboutUs />} />
          {/*<Route path="/glossary" element={<Glossary />} />*/}
          <Route
            path="/high-risk-businesses"
            element={<HighRiskBusinesses />}
          />
          <Route
            path="/high-risk-territories"
            element={<HighRiskTerritories />}
          />
          <Route path="/red-flags/:id" element={<RedFlags />} />
          <Route path="/red-flags/*" element={<RedFlags />} />
          <Route path="/intelligence-feed" element={<Home />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
