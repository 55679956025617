import { useState, useEffect } from 'react';
import { ExportItem } from './ExportItem';
import styles from './exportList.module.scss';

interface ListItem {
  id: number;
  name: string;
  description: string;
}

type Props = {
  data: any[];
  type: 'typology' | 'scenario';
};

const ExportList = ({ data, type }: Props) => {
  const [itemList, setItemList] = useState<ListItem[]>([]);

  useEffect(() => {
    setItemList(data);
  }, [data]);

  return (
    <ul className={styles.list}>
      <li
        className={`${styles.item} ${styles['item--heading']} ${styles.selection}`}
      >
        <input
          type="checkbox"
          name=""
          id=""
          className={`${styles.item__checkbox} ${styles.hidden} ${styles.selection}`}
        />
        <div className={styles.item__entries}>
          <p>ID</p>
          <p>Crime Type</p>
        </div>
      </li>
      {itemList.map((item) => (
        <ExportItem key={item.id} data={item} />
      ))}
    </ul>
  );
};

export default ExportList;
