import { useContext } from 'react';
import { SelectionHandlerContext } from '../../pages/Typologies/Typologies';
import styles from './scenarioList.module.scss';

type Props = {
  data: {
    id: number;
    name: string;
  };
  selectionMode: boolean;
  selectedItem: boolean;
  scenarioClickHandler: Function;
};

const ScenarioItem = ({
  data,
  selectionMode,
  selectedItem,
  scenarioClickHandler,
}: Props) => {
  const setSelectionHandler = useContext(SelectionHandlerContext);

  const checkboxHandler = (id: number) => {
    setSelectionHandler(id);
  };

  return (
    <li
      className={`${styles.item} ${
        selectionMode
          ? selectedItem
            ? `${styles['item--selected']} ${styles.selection}`
            : styles.selection
          : ''
      }`}
      onClick={() =>
        selectionMode ? checkboxHandler(data.id) : scenarioClickHandler(data)
      }
    >
      <input
        type="checkbox"
        className={`${styles.item__checkbox} ${
          selectionMode ? styles.selection : ''
        }`}
        checked={selectedItem}
        onChange={() => {}}
      />
      <div className={styles.item__entries}>
        <p>{data.id}</p>
        <p>{data.name}</p>
      </div>
    </li>
  );
};

export { ScenarioItem };
