import React from 'react';
import RegionTable from './RegionTable';
import CountriesTable from './CountriesTable';
import styles from './highRiskCorridors.module.scss';

type Props = {};

const HighRiskCorridors = (props: Props) => {
  return (
    <section className={styles.content}>
      <RegionTable />
      <CountriesTable />
    </section>
  );
};

export default HighRiskCorridors;
