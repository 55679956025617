import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { ReactComponent as IconCircleQuestion } from 'assets/icons/icon-circle-question.svg';
import SwitchInput from 'components/SwitchInput';
import Export from './Export';
import Sidebar from 'components/Sidebar';
import SidebarTypology from 'components/SidebarTypology';
import Typology from './TypologyDetail/Typology';
import Scenario from './ScenarioDetail/Scenario';
import icon from 'assets/rfa-logo.png';
import styles from './typologies.module.scss';
import Indicator from './IndicatorDetail/Indicator';
import {
  getIndicatorState,
  getScenarioState,
  getTypologyState,
} from 'helpers/getStateFromLocation';
import Graph from './Graph';
import TypologyTable from './TypologyDetail/TypologyTable/TypologyTable';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import GraphGuide from 'components/GraphGuide/GraphGuide';
import TypologyGuide from 'components/TypologyGuide';
import TypologyNavigation from 'components/SidebarTypology/TypologyNavigation';

export const SelectionHandlerContext = React.createContext(
  (id: number): void => {}
);

export const TypologySummaryContext = React.createContext((): void => {});

interface IndicatorType {
  id: number;
  name: string;
  description: string;
  strength: string;
  url: string;
}

interface ScenarioType {
  id: number;
  name: string;
  description: string;
  url: string;
  indicators: IndicatorType[];
}

interface TypologyType {
  id: number;
  name: string;
  description: string;
  url: string;
  scenarios?: ScenarioType[];
}

type Props = {
  typologyApiData: TypologyType[];
};

const Typologies = ({ typologyApiData }: Props) => {
  const [content, setContent] = useState('typologies');
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(true);
  const [showGraph, setShowGraph] = useState<boolean>(false);

  const {
    modalOpen: graphOpen,
    handleModalClose: graphModalClose,
    handleModalOpen: graphModalOpen,
  } = useModal();
  const {
    modalOpen: typologyOpen,
    handleModalClose: typologyModalClose,
    handleModalOpen: typologyModalOpen,
  } = useModal();

  //array of selected list item ids
  const [selectionList, setSelectionList] = useState<number[]>([]);
  //list of typology id's for the export table
  const [exportTypologyList, setExportTypologyList] = useState<any[]>([]);
  //list of scenario id's for the export table
  const [exportScenarioList, setExportScenarioList] = useState<any[]>([]);

  const navigate = useNavigate();
  const location = useLocation();

  //clicked typology id to switch to specific typology page
  const [clickedTypology, setClickedTypology] = useState<TypologyType | null>(
    null
  );
  //clicked scenario id to switch to specific scenario page
  const [clickedScenario, setClickedScenario] = useState<ScenarioType | null>(
    null
  );

  const [clickedIndicator, setClickedIndicator] =
    useState<IndicatorType | null>(null);

  const typologySummaryHandler = () => {
    if (location.pathname !== '/typologies') {
      setContent('typologies');
    }
  };

  //persist states based on url parameters
  useEffect(() => {
    if (location.pathname.includes('typology=')) {
      setClickedTypology(
        typologyApiData?.filter(
          (el) => el.id?.toString() === getTypologyState(location.pathname)
        )[0]
      );
    }
    if (location.pathname.includes('scenario=')) {
      setClickedScenario(
        typologyApiData
          ?.filter(
            (el) => el.id?.toString() === getTypologyState(location.pathname)
          )[0]
          .scenarios!.filter(
            (el) => el.id?.toString() === getScenarioState(location.pathname)
          )[0]
      );
    }
    if (location.pathname.includes('indicator=')) {
      setClickedIndicator(
        typologyApiData
          ?.filter(
            (el) => el.id?.toString() === getTypologyState(location.pathname)
          )[0]
          .scenarios!.filter(
            (el) => el.id?.toString() === getScenarioState(location.pathname)
          )[0]
          .indicators.filter(
            (el) => el.id?.toString() === getIndicatorState(location.pathname)
          )[0]
      );
    }
  }, [typologyApiData, location.pathname]);

  useEffect(() => {
    setExportTypologyList(
      typologyApiData?.filter((el) => selectionList.includes(el.id))
    );
    setExportScenarioList(
      typologyApiData
        ?.filter((el) => el.scenarios)
        .map((el) => el.scenarios)
        .flat()
        .filter((el) => selectionList.includes(el!.id))
    );
  }, [selectionList, typologyApiData]);

  const selectionHandler = (id: number) => {
    setSelectionList((prev) => {
      if (prev.includes(id)) {
        return prev.filter((el) => el !== id);
      }
      return [...prev, id];
    });
  };

  //const selectionExportHandler = () => {
  //  setContent('export');
  //};

  //click typology to go to specific page for that typology
  const typologyClickHandler = (data: TypologyType): void => {
    if (data.id === clickedTypology?.id) {
      navigate(`/typologies/typology=${data.id}`);
    } else {
      setClickedTypology(data);
      navigate(`/typologies/typology=${data.id}`);
    }
  };

  //click scenario to go to specific page for that scenario
  const scenarioClickHandler = (data: ScenarioType): void => {
    const [filterTypology] = typologyApiData.filter((el) =>
      el.scenarios?.some((scenario) => scenario.id === data.id)
    );

    if (data.id === clickedScenario?.id) {
      navigate(`/typologies/typology=${filterTypology.id}/scenario=${data.id}`);
    } else {
      setClickedTypology(filterTypology);
      setClickedScenario(data);
      navigate(`/typologies/typology=${filterTypology.id}/scenario=${data.id}`);
    }
  };

  const indicatorClickHandler = (data: IndicatorType): void => {
    const [filterScenario] = typologyApiData
      .filter((el) => el.scenarios)
      .map((el) => el.scenarios)
      .flat()
      .filter((scenario) => {
        return scenario?.indicators.some((el) => el.id === data.id);
      });
    setClickedScenario(filterScenario!);
    setClickedIndicator(data);
    navigate(`/red-flags/${data.id}`);
  };

  const graphClickHandler = () => {
    setShowGraph((prev) => !prev);
  };

  return (
    <main
      className={
        sidebarOpen
          ? styles.contentTypology
          : `${styles.contentTypology} ${styles['contentTypology--animate']}`
      }
    >
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h1
            className={`${styles.heading__typologies} ${
              content === 'export' ? '' : styles['heading__typologies--active']
            }`}
            onClick={() => {
              setContent('typologies');
            }}
          >
            Typologies and Scenarios
          </h1>
          {/*<span></span>
          <h3
            className={`${styles.heading__selections} ${
              content === 'export' ? styles['heading__selections--active'] : ''
            } ${
              selectionList.length > 0
                ? styles['heading__selections--filled']
                : ''
            }`}
            onClick={
              selectionList.length > 0 ? selectionExportHandler : () => {}
            }
          >
            Selections for export ({selectionList.length})
          </h3>*/}
        </div>
      </section>
      <section
        className={
          content !== 'typologies' || location.pathname !== '/typologies'
            ? styles.none
            : undefined
        }
      >
        <div className={styles.pageContent}>
          <div className={styles.selections}>
            {/*<SwitchInput
              label="Select the content for export"
              handler={() => {
                setSelectionMode((prev) => !prev);
              }}
            />*/}
            <SwitchInput label="Graph View" handler={graphClickHandler} />
            <div className={styles.guide} onClick={graphModalOpen}>
              <IconCircleQuestion />
              <p>Graph Guide</p>
            </div>
            <Modal isOpen={graphOpen} handleClose={graphModalClose}>
              <GraphGuide />
            </Modal>
            <div className={styles.guide} onClick={typologyModalOpen}>
              <IconCircleQuestion />
              <p>Typologies Guide</p>
            </div>
            <Modal isOpen={typologyOpen} handleClose={typologyModalClose}>
              <TypologyGuide />
            </Modal>
          </div>
        </div>
        {showGraph ? (
          <Graph />
        ) : (
          <div className={styles.content}>
            <SelectionHandlerContext.Provider value={selectionHandler}>
              {typologyApiData && (
                <>
                  <TypologyTable
                    data={typologyApiData}
                    typologyClickHandler={typologyClickHandler}
                    scenarioClickHandler={scenarioClickHandler}
                  />
                </>
              )}
            </SelectionHandlerContext.Provider>
          </div>
        )}
      </section>
      {typologyApiData && content === 'typologies' && (
        <TypologySummaryContext.Provider value={typologySummaryHandler}>
          <Routes>
            <Route
              path="/typology=:typologyId"
              element={
                clickedTypology && (
                  <Typology
                    clickedTypology={clickedTypology}
                    scenarioClickHandler={scenarioClickHandler}
                    indicatorClickHandler={indicatorClickHandler}
                  />
                )
              }
            />
            <Route
              path="/typology=:typologyId/scenario=:scenarioId"
              element={
                clickedScenario && (
                  <Scenario
                    clickedScenario={clickedScenario}
                    indicatorClickHandler={indicatorClickHandler}
                  />
                )
              }
            />
            <Route
              path="/typology=:typologyId/scenario=:scenarioId/indicator=:indicatorId"
              element={
                clickedIndicator && (
                  <Indicator clickedIndicator={clickedIndicator} />
                )
              }
            />
          </Routes>
        </TypologySummaryContext.Provider>
      )}
      {content === 'export' && (
        <Export
          exportScenarioList={exportScenarioList}
          exportTypologyList={exportTypologyList}
        />
      )}
      {typologyApiData && location.pathname.includes('/typologies/') && (
        <>
          <button
            title="Open/Close Sidebar"
            className={
              sidebarOpen
                ? 'menu__btn menu__btn--full'
                : 'menu__btn menu__btn--full menu__btn--hidden'
            }
            onClick={() => setSidebarOpen((prev) => !prev)}
          >
            {sidebarOpen ? 'Hide' : 'Navigation'}
            <img src={icon} alt={`sidebar ${sidebarOpen ? 'hide' : 'open'}`} />
          </button>
          <Sidebar full sidebarOpen={sidebarOpen}>
            <SidebarTypology>
              <TypologyNavigation
                data={typologyApiData}
                typologyClickHandler={typologyClickHandler}
                scenarioClickHandler={scenarioClickHandler}
              />
            </SidebarTypology>
          </Sidebar>
        </>
      )}
    </main>
  );
};

export default Typologies;
