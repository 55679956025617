import { useState } from 'react';
import styles from './exportList.module.scss';

type Props = {
  data: {
    id: number;
    name: string;
  };
};

const ExportItem = ({ data }: Props) => {
  const [checked, setChecked] = useState(true);

  const checkboxHandler = (id: number) => {
    setChecked((prev) => !prev);
  };

  return (
    <li
      className={`${styles.item} ${checked ? styles['item--selected'] : ''} ${
        styles.selection
      }`}
      onClick={() => checkboxHandler(data.id)}
    >
      <input
        type="checkbox"
        className={`${styles.item__checkbox} ${styles.selection}`}
        checked={checked}
        onChange={() => {}}
      />
      <div className={styles.item__entries}>
        <p>{data.id}</p>
        <p>{data.name}</p>
      </div>
    </li>
  );
};

export { ExportItem };
