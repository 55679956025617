import React, { useState } from 'react';
import HighRiskCorridors from './HighRiskCorridors';
import HighRiskCountries from './HighRiskCountries';
import HighRiskOverview from './HighRiskOverview';
import styles from './highRiskTerritories.module.scss';

type Props = {};

const HighRiskTerritories = (props: Props) => {
  //3 different tab states: 'overview', 'countries', 'corridors'.
  const [content, setContent] = useState<
    'overview' | 'countries' | 'corridors'
  >('overview');
  return (
    <main className="content">
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h3
            className={`${styles.heading__selection} ${
              content === 'overview' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'overview'
                ? () => {
                    setContent('overview');
                  }
                : () => {}
            }
          >
            Overview
          </h3>
          <span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'countries'
                ? styles['heading__selection--active']
                : ''
            }`}
            onClick={
              content !== 'countries'
                ? () => {
                    setContent('countries');
                  }
                : () => {}
            }
          >
            High-risk Countries
          </h3>
          <span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'corridors'
                ? styles['heading__selection--active']
                : ''
            }`}
            onClick={
              content !== 'corridors'
                ? () => {
                    setContent('corridors');
                  }
                : () => {}
            }
          >
            High-risk Corridors
          </h3>
        </div>
      </section>
      {content === 'overview' && <HighRiskOverview />}
      {content === 'countries' && <HighRiskCountries />}
      {content === 'corridors' && <HighRiskCorridors />}
    </main>
  );
};

export default HighRiskTerritories;
