import React from 'react';
import styles from './switchInput.module.scss';

type Props = {
  label: string;
  handler: Function;
  reverseLabel?: boolean;
};

const SwitchInput = ({ label, handler, reverseLabel }: Props) => {
  return (
    <div className={`${styles.wrapper} ${reverseLabel ? styles.reverse : ''}`}>
      <label id="switch" className={styles.switch}>
        <input type="checkbox" id="switch" onChange={() => handler()} />
        <span className={`${styles.slider} ${styles.round}`}>
          <span className={styles.icon}></span>
        </span>
      </label>
      <p className={styles.switch__label}>{label}</p>
    </div>
  );
};

export default SwitchInput;
