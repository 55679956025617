import React, { PropsWithChildren, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as IconClose } from 'assets/icons/icon-close.svg';
import styles from './modal.module.scss';

type ModalProps = {
  handleClose: () => void;
  isOpen?: boolean;
  className?: string;
};

const Modal = ({
  children,
  handleClose,
  isOpen,
  className = '',
}: PropsWithChildren<ModalProps>): ReactElement | null => {
  const root = document.getElementById('root');

  return isOpen && root
    ? createPortal(
        <>
          <div className={`${styles.content} ${className}`}>
            {children}
            <button type="button" onClick={handleClose} className={styles.btn}>
              <IconClose />
            </button>
          </div>

          <div className={styles.overlay} onClick={handleClose}></div>
        </>,
        root
      )
    : null;
};

export default Modal;
