import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { createMarkup } from 'helpers/createMarkup';
import ScenarioTable from '../ScenarioDetail/ScenarioTable';
import styles from '../typologies.module.scss';

interface IndicatorType {
  id: number;
  name: string;
  description: string;
  strength: string;
  url: string;
}

interface ScenarioType {
  id: number;
  name: string;
  description: string;
  url: string;
  indicators: IndicatorType[];
}

interface TypologyType {
  id: number;
  name: string;
  description: string;
  url: string;
  scenarios?: ScenarioType[];
}

type Props = {
  clickedTypology: TypologyType;
  scenarioClickHandler: (data: any) => void;
  indicatorClickHandler: (data: any) => void;
};

const Typology = ({
  clickedTypology,
  scenarioClickHandler,
  indicatorClickHandler,
}: Props) => {
  const crumbData = [
    {
      title: `Typology ${clickedTypology?.id}`,
      url: '#',
    },
  ];

  return clickedTypology ? (
    <section className={styles.typology__wrapper}>
      <Breadcrumbs crumbData={crumbData} />
      <h2>{clickedTypology.name}</h2>
      <h3>Description</h3>
      <div className={styles.typology__content}>
        <div
          className={
            clickedTypology.scenarios!.length === 0
              ? `${styles.typology__content__description} ${styles['typology__content__description--long']}`
              : styles.typology__content__description
          }
          dangerouslySetInnerHTML={createMarkup(clickedTypology.description)}
        ></div>
        {clickedTypology.scenarios!.length > 0 && (
          <ScenarioTable
            data={clickedTypology.scenarios!}
            scenarioClickHandler={scenarioClickHandler}
            indicatorClickHandler={indicatorClickHandler}
          />
        )}
      </div>
    </section>
  ) : null;
};

export default Typology;
