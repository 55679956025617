import styles from './sidebar.module.scss';

type Props = {
  sidebarOpen: boolean;
  children: React.ReactNode;
  full?: boolean;
};

const Sidebar = ({ sidebarOpen, children, full }: Props) => {
  return (
    <aside
      className={
        sidebarOpen
          ? `${styles.wrapper} ${full ? styles['wrapper--full'] : ''}`
          : `${styles.wrapper} ${styles['wrapper--hidden']} ${
              full ? styles['wrapper--full'] : ''
            }`
      }
    >
      {children}
    </aside>
  );
};

export default Sidebar;
