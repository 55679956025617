// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebarTypology_navigation__LU1ck {\n  padding: 0 2rem;\n}\n.sidebarTypology_navigation__LU1ck h5 {\n  color: var(--text-description);\n  font-size: 1.8rem;\n  font-weight: 400;\n  padding: 0 0 1rem;\n  margin-bottom: 2rem;\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.sidebarTypology_navigation__LU1ck .sidebarTypology_typologiesTable__hkPSp .sidebarTypology_MuiTableCell-root__I8cs0 {\n  border-bottom: none !important;\n}", "",{"version":3,"sources":["webpack://./src/components/SidebarTypology/sidebarTypology.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EACE,8BAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;AAEE;EACE,8BAAA;AAAJ","sourcesContent":[".navigation {\n  padding: 0 2rem;\n\n  h5 {\n    color: var(--text-description);\n    font-size: 1.8rem;\n    font-weight: 400;\n    padding: 0 0 1rem;\n    margin-bottom: 2rem;\n    display: flex;\n    align-items: center;\n    position: relative;\n  }\n\n  & .typologiesTable .MuiTableCell-root {\n    border-bottom: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "sidebarTypology_navigation__LU1ck",
	"typologiesTable": "sidebarTypology_typologiesTable__hkPSp",
	"MuiTableCell-root": "sidebarTypology_MuiTableCell-root__I8cs0"
};
export default ___CSS_LOADER_EXPORT___;
