// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".typologiesLanding_sidebar__list__a27ZY {\n  list-style: none;\n  padding: 3rem 0;\n}\n.typologiesLanding_sidebar__heading__T7Fm\\+ {\n  color: var(--text-description);\n  font-size: 1.8rem;\n  font-weight: 400;\n  padding-left: 3rem;\n}\n\n.typologiesLanding_content__rtQ3U {\n  display: block;\n}\n\n.typologiesLanding_gallery__EBEXe {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.typologiesLanding_gallery__EBEXe figure {\n  padding: 5rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.typologiesLanding_gallery__EBEXe figure > img {\n  max-height: 5rem;\n}\n.typologiesLanding_gallery__EBEXe figure > figcaption {\n  margin-top: 2rem;\n}\n.typologiesLanding_gallery__heading__Ri9qN {\n  text-align: center;\n  color: var(--text-heading);\n  margin-top: 5rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/TypologiesLanding/typologiesLanding.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,8BAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,cAAA;AAFF;;AAKA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAIE;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,gBAAA;AADN;AAGI;EACE,gBAAA;AADN;AAKE;EACE,kBAAA;EACA,0BAAA;EACA,gBAAA;AAHJ","sourcesContent":[".sidebar {\n  &__list {\n    list-style: none;\n    padding: 3rem 0;\n  }\n\n  &__heading {\n    color: var(--text-description);\n    font-size: 1.8rem;\n    font-weight: 400;\n    padding-left: 3rem;\n  }\n}\n\n.content {\n  display: block;\n}\n\n.gallery {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  & figure {\n    padding: 5rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    & > img {\n      max-height: 5rem;\n    }\n    & > figcaption {\n      margin-top: 2rem;\n    }\n  }\n\n  &__heading {\n    text-align: center;\n    color: var(--text-heading);\n    margin-top: 5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar__list": "typologiesLanding_sidebar__list__a27ZY",
	"sidebar__heading": "typologiesLanding_sidebar__heading__T7Fm+",
	"content": "typologiesLanding_content__rtQ3U",
	"gallery": "typologiesLanding_gallery__EBEXe",
	"gallery__heading": "typologiesLanding_gallery__heading__Ri9qN"
};
export default ___CSS_LOADER_EXPORT___;
