import React from 'react';
import ZoomCharts from 'components/ZoomCharts/ZoomCharts';
import styles from './typologies.module.scss';

type Props = {};

const graphQueryString =
  'typology_id=1000&typology_id=2000&&typology_id=3000&&typology_id=4000&&typology_id=5000&&typology_id=6000&&typology_id=7000&';

const Graph = (props: Props) => {
  return (
    <section className={styles.graph}>
      <div className={styles.graph__content}>
        <ZoomCharts
          baseUrl={process.env.REACT_APP_NEO4J_INITIAL}
          expandUrl={process.env.REACT_APP_NEO4J_EXPAND}
          typologyData={graphQueryString}
          scenarioData={''}
        />
      </div>
    </section>
  );
};

export default Graph;
