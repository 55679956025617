import React from 'react';
import styles from './themeSwitcher.module.scss';

type Props = {
  handler: Function;
};

const ThemeSwitcher = ({ handler }: Props) => {
  return (
    <label id="switch" className={styles.switch}>
      <input type="checkbox" id="slider" onChange={() => handler()} />
      <span className={`${styles.slider} ${styles.round}`}>
        <span className={styles.icon}></span>
      </span>
    </label>
  );
};

export default ThemeSwitcher;
