import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './tableStyling.scss';

function createData(typology: string, id: number, scenarios: any[]) {
  return {
    typology,
    id,
    scenarios: scenarios,
  };
}

interface IRow {
  row: any;
  typologyClickHandler: (data: {}) => void;
  scenarioClickHandler: (data: {}) => void;
}

function Row({ row, typologyClickHandler, scenarioClickHandler }: IRow) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        //onClick={() => setOpen(!open)}
      >
        <TableCell
          component="th"
          scope="row"
          onClick={() => typologyClickHandler(row)}
        >
          {row.typology}
        </TableCell>
        <TableCell align="right" onClick={() => setOpen(!open)}>
          <IconButton aria-label="expand row" size="small">
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Scenarios</TableCell>
                    <TableCell align="right">ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.scenarios
                    .sort((a: any, b: any) => a.id - b.id)
                    .map(
                      (scenarioRow: {
                        name: string;
                        url: string;
                        id: number;
                      }) => {
                        return (
                          <TableRow
                            key={scenarioRow.id}
                            onClick={() => scenarioClickHandler(scenarioRow)}
                          >
                            <TableCell component="th" scope="row">
                              {scenarioRow.name}
                            </TableCell>
                            <TableCell align="right">
                              {scenarioRow.id}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    typology: PropTypes.string,
    scenarios: PropTypes.array,
    id: PropTypes.number,
  }),
};

export default function CollapsibleTable({
  data,
  typologyClickHandler,
  scenarioClickHandler,
}: any) {
  const rows = [
    ...data.map((el: any) => createData(el.name, el.id, el.scenarios)),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" className={'typologiesTable'}>
        <TableHead>
          <TableRow>
            <TableCell>Typologies</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return (
              <Row
                key={row.typology}
                row={row}
                typologyClickHandler={typologyClickHandler}
                scenarioClickHandler={scenarioClickHandler}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
