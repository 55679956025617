import { useState, useEffect } from 'react';

export const useFetchData = (url: string, token: string) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverError, setServerError] = useState<string>('');

  useEffect(() => {
    if (!url || !token) return;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: new Headers({
            Authorization: `token ${token}`,
          }),
        });
        const resData = await res.json();
        setIsLoading(false);
        setData(resData);
      } catch (err) {
        setIsLoading(false);
        setServerError('Something went wrong retrieving the data...');
        console.error(err);
      }
    };
    fetchData();
  }, [url, token]);

  return { isLoading, data, serverError };
};
