// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar_wrapper__cC8jm {\n  padding: 5rem 0 4rem 0;\n  border-left: 2px solid var(--color-red);\n  background-color: var(--background-light);\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 55rem;\n  transition: right 0.3s;\n  align-self: stretch;\n  height: 100%;\n  overflow-y: scroll;\n}\n@media (max-width: 630px) {\n  .sidebar_wrapper__cC8jm {\n    display: none;\n  }\n}\n.sidebar_wrapper--hidden__CE7fe {\n  right: -55rem;\n}\n.sidebar_wrapper--full__U7jBm {\n  top: 1px;\n  height: calc(100% - 1px);\n  padding: 10rem 0 4rem 0;\n}\n.sidebar_wrapper__cC8jm::-webkit-scrollbar {\n  width: 0;\n}\n.sidebar_wrapper__cC8jm {\n  scrollbar-width: none;\n}", "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,uCAAA;EACA,yCAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;AACF;AACE;EAbF;IAcI,aAAA;EAEF;AACF;AAAE;EACE,aAAA;AAEJ;AACE;EACE,QAAA;EACA,wBAAA;EACA,uBAAA;AACJ;AAIE;EACE,QAAA;AAFJ;AAKE;EACE,qBAAA;AAHJ","sourcesContent":[".wrapper {\n  padding: 5rem 0 4rem 0;\n  border-left: 2px solid var(--color-red);\n  background-color: var(--background-light);\n  position: absolute;\n  top: 0;\n  right: 0;\n  width: 55rem;\n  transition: right 0.3s;\n  align-self: stretch;\n  height: 100%;\n  overflow-y: scroll;\n\n  @media (max-width: 630px) {\n    display: none;\n  }\n\n  &--hidden {\n    right: -55rem;\n  }\n\n  &--full {\n    top: 1px;\n    height: calc(100% - 1px);\n    padding: 10rem 0 4rem 0;\n  }\n\n  //scrollbar hide\n  //chrome\n  &::-webkit-scrollbar {\n    width: 0;\n  }\n  //mozilla\n  & {\n    scrollbar-width: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "sidebar_wrapper__cC8jm",
	"wrapper--hidden": "sidebar_wrapper--hidden__CE7fe",
	"wrapper--full": "sidebar_wrapper--full__U7jBm"
};
export default ___CSS_LOADER_EXPORT___;
