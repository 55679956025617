export const getTypologyState = (path: string) => {
  switch (true) {
    case path.includes('typology='):
      const typologyId = path.split('typology=')[1].split('/')[0];
      return typologyId;
    default:
      return '';
  }
};

export const getScenarioState = (path: string) => {
  switch (true) {
    case path.includes('scenario='):
      const scenarioId = path.split('scenario=')[1].split('/')[0];
      return scenarioId;
    default:
      return '';
  }
};

export const getIndicatorState = (path: string) => {
  switch (true) {
    case path.includes('indicator='):
      const indicatorId = path.split('indicator=')[1].split('/')[0];
      return indicatorId;
    default:
      return '';
  }
};
