import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './tableStyling.scss';

function createData(businessType: string, activities: string[]) {
  return {
    businessType,
    activities: [...activities],
  };
}

interface IRow {
  row: any;
}

function Row({ row }: IRow) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        onClick={() => setOpen(!open)}
      >
        <TableCell
          align="right"
          onClick={() => setOpen(!open)}
          style={{ paddingBottom: '1rem', paddingTop: '1rem', paddingRight: 0 }}
        >
          <IconButton aria-label="expand row" size="small">
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
        >
          {row.businessType}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '4.7rem' }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.activities.map((activity: string, i: number) => {
                    return (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          {activity}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    typology: PropTypes.string,
    scenarios: PropTypes.array,
    id: PropTypes.number,
  }),
};

export default function CollapsibleTable({ data }: any) {
  const rows = [
    ...data.map((el: any) =>
      createData(el['High-Risk_Business_Types'], el.Activities)
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="collapsible table"
        className={'highRiskBusinessesTable'}
      >
        <TableBody>
          {rows.map((row, i) => {
            return <Row key={i} row={row} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
