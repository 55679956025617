import { useState } from 'react';
//import Button from 'components/Button/Button';
//import { ReactComponent as IconUpload } from 'assets/icons/icon-upload.svg';
import styles from './expert.module.scss';

type Props = {};

const AskAnExpert = (props: Props) => {
  //const [showRequestCall, setShowRequestCall] = useState<boolean>(false);
  const [mailData, setMailData] = useState({
    contactInfo: '',
    subject: '',
    body: '',
  });

  return (
    <section className={styles.expert}>
      <div className={styles.expert__help}>
        <p>
          Please read the user guidance in the right panel before Our Expert
          would aim to answer your question in X days. Before asking a question,
          please get familiar with the ‘User Guidance’ available on the right
          side.
        </p>
        <p>
          Ask an Expert section is specifically designed to cater to your needs.
          Our Expert will offer advice to any of your investigation inquiries,
          provide clarification or more details on our typologies, scenarios and
          red flags. If you have any suggestions on how we could enrich our
          Typologies, this is also the right place for you to share that with
          our team
        </p>
        <p>
          If you need RFA Web portal support, please visit{' '}
          <a
            href="https://redflagaccelerator.com/"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'var(--color-red)' }}
          >
            RedFlag Accelerator
          </a>{' '}
          website.
        </p>
      </div>
      <form
        action=""
        className={styles.expert__form}
        onSubmit={(e) => e.preventDefault()}
      >
        {/*<div className={styles.expert__form__controls}>
          <select name="" id="" className={styles.expert__form__select}>
            <option value="">Please select your question category</option>
            <option value="">Typology Advice</option>
            <option value="">Financial Crime Investigation</option>
            <option value="">Portal Help</option>
            <option value="">Other</option>
          </select>
          <label htmlFor="expert-upload" className={styles.expert__form__label}>
            Please don't upload Personally identifiable information
            <IconUpload />
            <input
              className={styles.expert__form__upload}
              type="file"
              name=""
              id="expert-upload"
            />
          </label>
        </div>*/}
        <div className={styles.expert__form__controls}>
          <input
            className={styles.expert__form__input}
            required
            type="email"
            placeholder="Contact e-mail address ..."
            onChange={(e) =>
              setMailData((prev) => {
                return { ...prev, contactInfo: e.target.value };
              })
            }
          />
        </div>
        <div className={styles.expert__form__controls}>
          <input
            className={styles.expert__form__input}
            required
            type="text"
            placeholder="Summary of your question ..."
            onChange={(e) =>
              setMailData((prev) => {
                return { ...prev, subject: e.target.value };
              })
            }
          />
        </div>
        <textarea
          className={styles.expert__question}
          required
          placeholder="Enter your question here ..."
          onChange={(e) =>
            setMailData((prev) => {
              return { ...prev, body: e.target.value };
            })
          }
        ></textarea>
        <div className={styles.expert__form__submit}>
          {/*<div className={styles.expert__form__submit__options}>
            <p>How would you like to be informed of replies:</p>
            <div className={styles.expert__form__submit__options__radio}>
              <input
                type="radio"
                name="radio-contact"
                id="radio-notify"
                checked={!showRequestCall}
                onChange={() => {
                  setShowRequestCall(false);
                }}
              />
              <label htmlFor="radio-notify">
                Notify me of replies by e-mail
              </label>
            </div>
            <div className={styles.expert__form__submit__options__radio}>
              <input
                type="radio"
                name="radio-contact"
                id="radio-call"
                checked={showRequestCall}
                onChange={() => setShowRequestCall(true)}
              />
              <label htmlFor="radio-call">
                Request a phone call about your question from a subject matter
                expert
              </label>
            </div>
            {showRequestCall ? (
              <div>
                <p>Phone number (format: 123 123 123)</p>
                <input
                  className={`${styles.expert__form__input} ${styles['expert__form__input--notify']}`}
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                />
                <p>Preferred time</p>
                <select name="" id="" className={styles.expert__form__select}>
                  <option value="">Choose available time</option>
                  <option value="">8:00</option>
                  <option value="">9:00</option>
                  <option value="">10:00</option>
                  <option value="">11:00</option>
                </select>
              </div>
            ) : null}
          </div>*/}
          <div className={styles.expert__form__submit__buttons}>
            {/*<button className={styles.expert__form__submit__buttons__cancel}>
              Cancel
            </button>*/}
            {mailData.body && mailData.contactInfo && mailData.subject ? (
              <a
                className={styles.submit}
                href={`mailto:rfa@redflagaccelerator.com?subject=${mailData.contactInfo}-${mailData.subject}&body=${mailData.body}`}
              >
                Submit
              </a>
            ) : (
              <button
                className={`${styles.submit} ${styles['submit--btn']}`}
                type="submit"
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

export default AskAnExpert;
