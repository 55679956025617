import React from 'react';
import styles from './about.module.scss';

type Props = {};

const HumanCrime = (props: Props) => {
  return (
    <div className={styles.humanCrime}>
      <p>
        Human trafficking is one of the most widespread crimes in the world and
        yet rarely spoken about. It is highly sustainable, extremely profitable,
        estimated to be worth $150 billion globally, affects over 40 million
        people worldwide, and is an ever-increasing industry as global
        conflicts, health, economic crisis, and climate change drive human
        displacement and vulnerability. It also carries a low risk of punity for
        the perpetrators.
      </p>
      <p>
        Victims are exploited in illegal operations, as well as in commercial
        settings appearing legitimate: construction, farm labour, factories,
        hotels, car washes, nail bars and others. The majority of crime proceeds
        are processed by the financial sector through hundreds of thousands of
        ordinary looking transactions. To date, less than 1% of human
        trafficking proceeds flowing through our banking systems are detected
        and investigated by law enforcement. Only an estimated 0.8% of criminals
        are caught and an unbelievable percentage of 0.2% victims are helped
        annually. The key challenges preventing the financial sector from
        identifying and acting upon these crimes include insufficient resources
        to stay on top of the new typologies, the pace of change in the
        traffickers’ modus operandi, the scale of regulatory requirements and
        the volume of data hidden in siloed systems.
      </p>
      <p>
        The rapid advances in information technology and internet accessibility
        across the globe have contributed to an unprecedented growth in online
        child sexual exploitation, as never before has it been easier for
        criminals to buy and sell Child Sexual Abuse Material (CSAM) online and
        hide their identity and proceeds. The vastness of the Internet (both
        surface and dark web), along with the sheer number of online platforms,
        apps and tools available, allows perpetrators to commit the crimes
        against children across multiple jurisdictions, using multiple
        communication channels and multiple payment methods, and hide in plain
        sight. Child sexual exploitation is now a global industry, abusing
        children at scale and estimated to generate $20 billion a year, while
        there are still dozens of countries with no legislation in place to
        address this type of offences.
      </p>
      <p>
        We introduced online child sexual exploitation typologies to the RedFlag
        Accelerator suite to accelerate the global efforts to disrupt this
        horrific crime. The financial dimension of child sexual exploitation
        includes payments, purchases and proceeds associated with the access and
        consumption or production and distribution of CSAM. Our typologies are
        targeted to proactively identify and report suspicious transactions that
        can considerably improve timely identification of criminals and
        interventions to protect children, by providing law enforcement with
        actionable intelligence relating to financial flows and parties
        involved.
      </p>
    </div>
  );
};

export default HumanCrime;
