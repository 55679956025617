import Hero from 'components/Hero';
import Categories from 'components/Categories';
import DashboardCard from 'components/DashboardCard';
import categories from 'data/typology-categories.json';
import styles from './typologiesLanding.module.scss';
import figure1 from 'assets/images/typology-landing-crime.png';
import figure2 from 'assets/images/typology-landing-persona.png';
import figure3 from 'assets/images/typology-landing-data.png';

type Props = {};

const TypologiesLanding = (props: Props) => {
  return (
    <main className={'content'}>
      <Hero
        title="RedFlag Accelerator Typologies"
        description="Accelerate the search by accessing the most comprehensive set of global human trafficking and exploitation red flags in context."
      />
      <div className={styles.content}>
        <h2 className={styles.gallery__heading}>
          Browse, search, filter and export content relevant for you:
        </h2>
        <div className={styles.gallery}>
          <figure>
            <img src={figure1} alt="Crime-based typologies" />
            <figcaption>Crime-based typologies</figcaption>
          </figure>
          <figure>
            <img src={figure2} alt="Persona-based scenarios" />
            <figcaption>Persona-based scenarios</figcaption>
          </figure>
          <figure>
            <img src={figure3} alt="Data-driven red flags" />
            <figcaption>Data-driven red flags</figcaption>
          </figure>
        </div>
        <Categories
          gridCount={2}
          sidebarOpen={false}
          style={{
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          {categories.map((el) => (
            <DashboardCard {...el} key={'card-' + el.id} />
          ))}
        </Categories>
      </div>
    </main>
  );
};

export default TypologiesLanding;
