import { useState } from 'react';
import AskAnExpert from './AskAnExpert';
//import YourQuestions from './YourQuestions';
//import OthersQuestions from './OthersQuestions';
import styles from './expert.module.scss';

type Props = {};

const Expert = (props: Props) => {
  //3 different tab states: 'expert', 'yours', 'others'.
  const [content, setContent] = useState('expert');

  return (
    <main className="content">
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h1
            className={`${styles.heading__selection} ${
              content === 'expert' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'expert'
                ? () => {
                    setContent('expert');
                  }
                : () => {}
            }
          >
            Ask an Expert
          </h1>
          {/*<span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'yours' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'yours'
                ? () => {
                    setContent('yours');
                  }
                : () => {}
            }
          >
            Your Questions
          </h3>*/}
          {/*<span></span>*/}
          {/*<h3
            className={`${styles.heading__selection} ${
              content === 'others' ? styles['heading__selection--active'] : ''
            }`}
            style={{ color: 'var(--text-description)' }}
            onClick={
              content !== 'others'
                ? () => {
                    //setContent('others');
                  }
                : () => {}
            }
          >
            Others' Questions (TBA)
          </h3>*/}
        </div>
      </section>
      {content === 'expert' && <AskAnExpert />}
      {/*{content === 'yours' && <YourQuestions />}*/}
      {/*{content === 'others' && <OthersQuestions />}*/}
    </main>
  );
};

export default Expert;
