import React from 'react';
import styles from './guidance.module.scss';

type Props = {};

const Implementation = (props: Props) => {
  return (
    <div className={styles.implementation}>
      <h3>Implementation Guidelines</h3>
      <p>
        To benefit the most from the RedFlag Accelerator Portal, users are
        advised to take following steps:
      </p>
      <ul>
        <li>
          incorporate human trafficking and child sexual exploitation risks into
          country, industry and customer risk rating methodologies;
        </li>
        <li>
          introduce within the institution a risk function that is dedicated to
          social concerns, including human crimes
        </li>
        <li>
          incorporate the RedFlag Accelerator scenarios and red flags into
          financial crime investigations and monitoring systems;
        </li>
        <li>
          include the RedFlag Accelerator scenarios and red flags at customer
          onboarding and CDD, EDD or KYC reviews accordingly;
        </li>

        <li>
          have a dedicated human trafficking typologies SME within the
          institution to coordinate all internal activities related to the topic
          and external stakeholder engagement;
        </li>
        <li>
          create and conduct regular training for the staff on the new trends in
          typologies;
        </li>
        <li>
          participate in initiatives to raise the industry’s standard globally
          (such as data sharing, new technologies, responsible business
          practices, ethical investment etc.).
        </li>
      </ul>

      <h3>Governance Steps</h3>
      <p>
        Create a centralised framework and oversight mechanism as an important
        first step in trying to ensure that complete and comprehensive responses
        are delivered to each suspected human trafficking and/or child sexual
        exploitation investigations in order to:
      </p>
      <ul>
        <li>minimise the chances of duplication of efforts;</li>
        <li>increase investigation efficiency;</li>
        <li>enhance the quality of investigation;</li>
        <li>
          allow for proven expertise to be developed and shared with members
          outside of the specialised team;
        </li>
        <li>
          understand how Human trafficking, in all its forms, is managed within
          an institution through analysing accumulated data that could include
          the number of cases worked, suspicious activity reports / suspicious
          transaction reports (SARs/STRs) filed, and/or number of arrests made.
        </li>
      </ul>

      <h3>Operational Steps</h3>
      <p>
        When carrying out due diligence processes, according to the regulations
        FIs and MSBs must investigate the individuals and businesses to which
        they provide services. While this screening is routine for detecting
        other forms of money laundering, human trafficking and child sexual
        exploitation are relatively new categories. To detect this crime, the
        financial sector must apply appropriate risk profiling alongside red
        flags, balanced against available resources and system constraints.
      </p>
      <br />
      <p>
        To ensure the quality of suspicious activity reports being submitted, it
        is also recommended to consider the potential for innovative proactive
        tactics to be adopted in trying to identify suspected human trafficking
        activity. Examples of these proactive methods include:
      </p>
      <ul>
        <li>retroactive review of previously closed cases;</li>
        <li>historical adverse media (negative news) searches;</li>
        <li>data mining suspicious activity report analytics;</li>
        <li>observing business activity in high risk sectors.</li>
      </ul>

      <p>
        When implementing the applicable scope of the RedFlag Accelerator
        scenarios and red flags, to return more accurate results and eliminate
        the potential false positives ensure that:
      </p>
      <ul>
        <li>
          behavioural data (face to face observations, front-line staff
          training, system) records are accessible;
        </li>
        <li>
          customer data (KYC, demographics, risk profile, account information)
          records are accessible;
        </li>
        <li>
          transactional data (all products, channels and transactions) records
          are accessible;
        </li>
        <li>
          benchmark and exclude from the scope ‘normal’ expected behaviours.
        </li>
      </ul>
    </div>
  );
};

export default Implementation;
