import React from 'react';
import styles from './guidance.module.scss';

type Props = {};

const Recommendation = (props: Props) => {
  return (
    <div className={styles.recommendation}>
      <p>
        To assist users in implementation of effective measures against human
        trafficking and child sexual exploitation, there are several
        recommendations which are divided into the following categories:
      </p>
      <h3>Contextual Data</h3>
      <ul>
        <li>
          establish a solid foundation of Human trafficking based on existing
          international law;
        </li>
        <li>
          outline individual definitions of major forms of human trafficking and
          child sexual exploitation;
        </li>
        <li>provide geography-specific information where available;</li>
        <li>
          include investigatory strategies and approaches supporting the
          conversion of financial intelligence to quality SARs/STRs;
        </li>
        <li>
          incorporate contextual information to support specific red flags.
        </li>
      </ul>
      <h3>Relevant Red Flags</h3>
      <ul>
        <li>
          outline the methodology utilised to adapt scenarios to local
          jurisdictions;
        </li>
        <li>
          prioritise focus on unique indicators related specifically to the
          various forms of Human trafficking applicable for the business type
          (customer type, jurisdictions, products);
        </li>
        <li>
          test and rate or rank individual red flags on their effectiveness;
        </li>
        <li>
          delineate red flags across three general categories (behavioural,
          customer and transactional);
        </li>
        <li>
          indicate a standard review cycle for policy and process that are
          predominantly red flag-focused to be updated with new intelligence;
        </li>
        <li>
          consolidate common information and/or intelligence that relates to
          more than one type of human crimes.
        </li>
      </ul>
      <h3>Operational Failures</h3>
      <ul>
        <li>
          compile a summary of all typologies and red flags into one location
          for easy access and review;
        </li>
        <li>
          create keywords to be included in SARs/STRs for human trafficking and
          child sexual exploitation activity identification;
        </li>
        <li>
          compile specific materials and resources dedicated to human
          trafficking and child sexual exploitation typologies.
        </li>
      </ul>
      <p>
        Successful implementation of the recommendations above will contribute
        to the standardisation of an investigation framework that can be
        considered and communicated across the sector and beyond. Wider best
        practice for the financial sector to raise the industry standard is
        establishing or participating in public/private partnerships to combat
        financial crime and, in particular, human trafficking and child sexual
        exploitation. This collaboration provides significant benefits for
        participants as evident from a number of successful initiatives (e.g.,
        JMLIT in the UK) such as establishing stronger relationships with
        industry peers and law enforcement and strengthening commitments to the
        community and social responsibility.
      </p>
    </div>
  );
};

export default Recommendation;
