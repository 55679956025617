import { Link } from 'react-router-dom';
import styles from './header.module.scss';
//import { ReactComponent as IconSearch } from 'assets/icons/icon-search.svg';
import rfaLogo from 'assets/rfa-logo.png';
import ThemeSwitcher from '../ThemeSwitcher';

type Props = {
  themeSwitchHandler: Function;
};

const Header = ({ themeSwitchHandler }: Props) => {
  return (
    <header className={styles.wrapper}>
      <div className={styles.container}>
        <Link to="/" className={styles.logo}>
          <img src={rfaLogo} alt="red flag accelerator" />
        </Link>
        {/*<IconSearch />
        <input
          type="search"
          name="header-search"
          id="header-search"
          placeholder="Search something"
          className={styles.search}
        />*/}
        <ThemeSwitcher handler={themeSwitchHandler} />
      </div>
    </header>
  );
};

export default Header;
