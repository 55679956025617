import { useEffect } from 'react';
import zc from '@dvsl/zoomcharts';
import styles from './zoomCharts.module.scss';
import { useLoadNeo4jData } from '../../hooks/useLoadNeo4jData';
import risk_yellow from 'assets/icons/graph_icons/Risk-Yellow.svg';
import risk_red from 'assets/icons/graph_icons/Risk-Red.svg';

const ZoomCharts = ({ baseUrl, expandUrl, typologyData, scenarioData }) => {
  const graphUrl = baseUrl + '?' + typologyData + scenarioData;

  const { data } = useLoadNeo4jData(
    graphUrl,
    process.env.REACT_APP_NEO4J_USERNAME,
    process.env.REACT_APP_NEO4J_PASSWORD
  );

  const NetChart = zc.NetChart;

  window.ZoomChartsLicense = process.env.REACT_APP_ZOOM_LICENSE
    ? `${process.env.REACT_APP_ZOOM_LICENSE}: ZoomCharts SDK Internal Use license for RedCompass Ltd. Valid for 1 chart developers and 4 chart viewers`
    : '';
  window.ZoomChartsLicenseKey = process.env.REACT_APP_ZOOM_LICENSE_KEY
    ? process.env.REACT_APP_ZOOM_LICENSE_KEY
    : '';

  useEffect(() => {
    // eslint-disable-next-line
    let chart = new NetChart({
      navigation: {
        focusNodeExpansionRadius: 0,
        mode: 'showall',
      },
      layout: {
        mode: 'hierarchy',
        rowSpacing: 80,
      },
      info: {
        enabled: true,
        nodeContentsFunction: function (itemData, item) {
          return (
            "<div style='margin:auto; width:200px; height:100%; padding: 10px; background-color: #ffffff;'>" +
            "<h3 style='font-weight: 300; font-size: 21px; color: #ba0020; padding-bottom: 3px; margin:0px; background-color: #ffffff;'>" +
            itemData.properties.name.replace(/_/g, ' ') +
            '</h3>' +
            // eslint-disable-next-line
            (itemData.properties.description != undefined
              ? "<p style='font-size: 13px;font-family: Arial, Helvetica, sans-serif;font-weight: 300;padding:5px'>" +
                itemData.properties.description.slice(0, 200) +
                '...<a href="#" style="color: var(--color-red);">Read more</a>' +
                '</p>'
              : '') +
            '</div>'
          );
        },
      },
      legend: {
        enabled: true,
        padding: 6,
        marker: { size: 22 },
        maxLineSymbols: 12,
      },
      style: {
        nodeClasses: [
          {
            className: 'Typology',
            style: {
              fillColor: '#5F6369',
              lineColor: '#5F6369',
              labelStyle: {
                textStyle: {
                  fillColor: '#FFFFFF',
                },
              },
            },
          },
          {
            className: 'Retail',
            nameLegend: 'Retail Red Flag',
            style: {
              fillColor: '#800015',
              lineColor: '#800015',
              labelStyle: {
                textStyle: {
                  fillColor: '#FFFFFF',
                },
              },
            },
          },
          {
            className: 'Business',
            nameLegend: 'Business Red Flag',
            style: {
              fillColor: '#ba0020',
              lineColor: '#ba0020',
              labelStyle: {
                textStyle: {
                  fillColor: '#FFFFFF',
                },
              },
            },
          },
          {
            className: 'Scenario',
            style: {
              fillColor: '#b2b5b6',
              lineColor: '#b2b5b6',
              labelStyle: {
                textStyle: {
                  fillColor: '#FFFFFF',
                },
              },
            },
          },
        ],
        linkClasses: [],
        node: {
          //display: 'roundtext',
        },
        nodeHovered: {
          shadowColor: '#00133633',
          shadowBlur: 30,
        },
        nodeSelected: {
          shadowColor: '#00133633',
          shadowBlur: 30,
          fillColor: '#000000',
          lineColor: '#000000',
          labelStyle: {
            textStyle: {
              fillColor: '#FFFFFF',
            },
          },
        },
        linkHovered: {
          shadowColor: '#00133633',
          shadowBlur: 0,
        },
        nodeStyleFunction: function (node) {
          node.display = 'roundtext';
          node.label = node.data.properties.name.replace(/_/g, ' ');
          var score_icon;
          if (node.data.additional_params) {
            if (node.data.labels.includes('Scenario')) {
              if (node.data.additional_params.scenario_score > 0) {
                score_icon = risk_red;
              } else if (node.data.additional_params.indicator_score > 0) {
                score_icon = risk_yellow;
              }
            } else if (node.data.labels.includes('Indicator')) {
              if (
                node.data.additional_params.scenario_score > 0 &&
                node.data.additional_params.indicator_score > 0
              ) {
                score_icon = risk_red;
              }
            }
          }
          //node.className = node.data.labels.filter(label => label != "Typologies")[0]
          //node.fillColor = nodes_colors[node.className]
          //node.fillColor = node.data._color;
          if (score_icon) {
            node.items.push({
              image: score_icon,
              px: -0.7,
              py: -0.7,
              x: 0,
              y: 0,
              scaleWithZoom: true,
            });
          }
        },
        link: {
          fillColor: '#5F6369',
        },
      },
      container: styles.demo,
      data: [
        {
          preloaded: data,
        },
      ],
      events: {
        onDoubleClick: graphDoubleClick,
      },
      toolbar: {
        fullscreen: true,
        enabled: true,
      },
      interaction: {
        resizing: {
          enabled: false,
        },
      },
    });

    function getNewData(node) {
      const NEO4J_API_CREDENTIALS = btoa(
        `${process.env.REACT_APP_NEO4J_USERNAME}:${process.env.REACT_APP_NEO4J_PASSWORD}`
      );
      var xobj = new XMLHttpRequest();
      const typesStr = node.data.labels
        ?.map((el, i) => {
          return i === 0 ? 'source_type=' + el : '&source_type=' + el;
        })
        .join('');
      xobj.open(
        'GET',
        expandUrl + '?' + typesStr + '&source_id=' + node.data.properties.id,
        false
      );
      xobj.setRequestHeader('Authorization', 'Basic ' + NEO4J_API_CREDENTIALS);
      xobj.send(null);
      return JSON.parse(xobj.responseText);
    }

    function graphDoubleClick(event) {
      if (event.clickNode) {
        var new_data = getNewData(event.clickNode);
        chart.addData(new_data);
      }
    }
  });

  return <div id={styles.demo}></div>;
};

export default ZoomCharts;
