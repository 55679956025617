import { useState } from 'react';
//import { ReactComponent as IconCircleQuestion } from 'assets/icons/icon-circle-question.svg';
//import { ReactComponent as IconAlert } from 'assets/icons/icon-alert.svg';
//import Export from 'pages/Typologies/Export';
import styles from './highRiskBusinesses.module.scss';
import Businesses from './Businesses/Businesses';

type Props = {};

const HighRiskBusinesses = (props: Props) => {
  const [content, setContent] = useState<'business' | 'export'>('business');
  return (
    <main className="content">
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h3
            className={`${styles.heading__selection} ${
              content === 'business' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'business'
                ? () => {
                    setContent('business');
                  }
                : () => {}
            }
          >
            High Risk Businesses
          </h3>
          {/*<span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'export' ? styles['heading__selection--active'] : ''
            }`}
            //onClick={
            //  content !== 'export'
            //    ? () => {
            //        setContent('export');
            //      }
            //    : () => {}
            //}
          >
            Selections for export (0)
          </h3>*/}
        </div>
        {/*<div className={styles.selections}>
          <div
            className={styles.guide}
            onClick={() => console.log('guide modal')}
          >
            <IconCircleQuestion />
            <p>Guide</p>
          </div>
          <div
            className={styles.whatsNew}
            onClick={() => console.log('whats new')}
          >
            <IconAlert />
            <p>What's New</p>
          </div>
        </div>*/}
      </section>
      {content === 'business' && <Businesses />}
      {/*{content === 'export' && (
        <Export exportScenarioList={[]} exportTypologyList={[]} />
      )}*/}
    </main>
  );
};

export default HighRiskBusinesses;
