import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as IconCircleQuestion } from 'assets/icons/icon-circle-question.svg';
//import { ReactComponent as IconAlert } from 'assets/icons/icon-alert.svg';
import { ReactComponent as IconDownload } from 'assets/icons/icon-download.svg';
import styles from './redFlags.module.scss';
import RedFlagTable from './RedFlagTable';
import useModal from 'hooks/useModal';
import Modal from 'components/Modal';
import TypologyGuide from 'components/TypologyGuide';
import RedFlagFilter from './RedFlagFilter';
import filterData from 'data/redFlagsFilter.json';

type Props = {};

const RedFlags = (props: Props) => {
  const [content, setContent] = useState<'flags' | 'export'>('flags');

  const params = useParams();

  const {
    modalOpen: typologyOpen,
    handleModalClose: typologyModalClose,
    handleModalOpen: typologyModalOpen,
  } = useModal();

  useEffect(() => {
    if (params.id) {
      const elementToScroll = document.getElementById('indicator-' + params.id);
      elementToScroll?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [params.id]);

  return (
    <main className="content">
      <section className={styles.pageHeader}>
        <div className={styles.heading}>
          <h3
            className={`${styles.heading__selection} ${
              content === 'flags' ? styles['heading__selection--active'] : ''
            }`}
            onClick={
              content !== 'flags'
                ? () => {
                    setContent('flags');
                  }
                : () => {}
            }
          >
            Red Flags
          </h3>
          {/*<span></span>
          <h3
            className={`${styles.heading__selection} ${
              content === 'export' ? styles['heading__selection--active'] : ''
            }`}
            //onClick={
            //  content !== 'export'
            //    ? () => {
            //        setContent('export');
            //      }
            //    : () => {}
            //}
          >
            Selections for export (0)
          </h3>*/}
        </div>
        <div className={styles.selections}>
          <div className={styles.guide} onClick={typologyModalOpen}>
            <IconCircleQuestion />
            <p>Typologies Guide</p>
          </div>
          <Modal isOpen={typologyOpen} handleClose={typologyModalClose}>
            <TypologyGuide />
          </Modal>
          {/*<div
            className={styles.whatsNew}
            onClick={() => console.log('whats new')}
          >
            <IconAlert />
            <p>What's New</p>
          </div>*/}
          <a
            className={styles.download}
            href="https://redcompasslabs.com/hubfs/redflags_full_list.csv"
            target="_blank"
            rel="noreferrer"
            download
          >
            <IconDownload />
            Download CSV
          </a>
        </div>
        <div className={styles.filter}>
          {filterData.map((filter) => (
            <RedFlagFilter
              data={filter.options}
              filterLabel={filter.label}
              param={filter.param}
              key={filter.param}
            />
          ))}
        </div>
      </section>
      {content === 'flags' && <RedFlagTable />}
      {/*{content === 'export' && (
    <Export exportScenarioList={[]} exportTypologyList={[]} />
  )}*/}
    </main>
  );
};

export default RedFlags;
