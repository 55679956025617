import {
  ReactChild,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from 'react';
import { useLocation, Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReactComponent as IconTypologies } from 'assets/icons/icon-typologies.svg';
import './tableStyling.scss';
import noResultsSearchImage from 'assets/typologies-homepage.png';
import redFlagData from 'data/red-flags.json';

const RedFlagTable = () => {
  const [data, setData] = useState(redFlagData);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const filters = {
    account_type: searchParams.get('account_type'),
    crime: searchParams.get('crime'),
    indicator_type: searchParams.get('indicator_type'),
    actor_type: searchParams.get('actor_type'),
    group: searchParams.get('group'),
    Significance: searchParams.get('Significance'),
    scenario_name: searchParams.get('scenario_name'),
  };

  const activeFilters = Object.entries(filters).filter(
    ([param, value]): any => value !== null && value !== ''
  );

  useEffect(() => {
    //reset data if no filters
    if (activeFilters.length === 0) {
      setData(redFlagData);
      return;
    }

    //set the data based on the filters if filters exist

    const uniqueFilteredData = redFlagData.filter((el) => {
      return activeFilters.every(([param, filters]) => {
        return filters
          ?.split(',')
          .some((filter) => el[param as keyof typeof el].includes(filter));
      });
    });

    setData(uniqueFilteredData);
  }, [location.search]);

  return data.length > 0 ? (
    <section className={'table-container'}>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '95vh' }}>
          <Table
            stickyHeader
            aria-label="red flags table"
            className={'redFlagTable'}
          >
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Red Flag</TableCell>
                <TableCell>Indicator Group</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Aggravating Factor</TableCell>
                <TableCell>Significance</TableCell>
                <TableCell>Data Sources</TableCell>
                <TableCell>Data Points</TableCell>
                <TableCell>Reference Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  id={'indicator-' + row.id}
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell style={{ minWidth: '20rem' }}>
                    {row.name}
                  </TableCell>
                  <TableCell style={{ minWidth: '15rem' }}>
                    {row.group}
                  </TableCell>
                  <TableCell style={{ minWidth: '20rem' }}>
                    {row.description}
                  </TableCell>
                  <TableCell style={{ minWidth: '20rem' }}>
                    <ul className={'list'}>
                      {row.Aggravating_Factors.map(
                        (
                          el:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined,
                          i: number
                        ) => (
                          <li
                            key={i}
                            className={'list__item list__item--noBullet'}
                          >
                            {el}
                          </li>
                        )
                      )}
                    </ul>
                  </TableCell>
                  <TableCell className={'significance'}>
                    {Array(+row.Significance)
                      .fill('1')
                      .map((el, i) => (
                        <IconTypologies key={i} />
                      ))}
                  </TableCell>
                  <TableCell style={{ minWidth: '15rem' }}>
                    <ul className={'list'}>
                      {row.Data_Sources.map(
                        (
                          el:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined,
                          i: number
                        ) => (
                          <li key={i} className={'list__item'}>
                            {el}
                          </li>
                        )
                      )}
                    </ul>
                  </TableCell>
                  <TableCell style={{ minWidth: '25rem' }}>
                    <ul className={'list'}>
                      {row.Data_Points.map(
                        (
                          el:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined,
                          i: number
                        ) => (
                          <li key={i} className={'list__item'}>
                            {el}
                          </li>
                        )
                      )}
                    </ul>
                  </TableCell>
                  <TableCell>
                    <ul className={'list'}>
                      {row.Reference_Data.map(
                        (
                          refData:
                            | boolean
                            | ReactChild
                            | ReactFragment
                            | ReactPortal
                            | null
                            | undefined,
                          i: number
                        ) => (
                          <li key={i} className={'list__item'}>
                            {refData}
                          </li>
                        )
                      )}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </section>
  ) : (
    <div className="no-result">
      <img src={noResultsSearchImage} alt="" />
      <p>
        No results for this query. Modify the filters or
        <Link to="/expert">contact our experts</Link>.
      </p>
    </div>
  );
};

export default RedFlagTable;
