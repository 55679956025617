import React from 'react';
import styles from './about.module.scss';

type Props = {};

const Sources = (props: Props) => {
  return (
    <div>
      <p>
        To ensure the RedFlag Accelerator Typologies is meaningful, practical,
        and comprehensive, meticulous research work was undertaken to
        investigate and map the footprint of human trafficking in financial
        services. During this process, we identified and consulted numerous
        leading source publications, such as Thomson Reuters Foundation reports
        produced in collaboration with three regional Banks Alliances (Asia
        Pacific, European and North American), guidelines from governments,
        regulators, and authorities such as FATF, data from law enforcement
        agencies, leading global initiatives and NGOs. We extracted over 1,000
        red flags and scrutinized hundreds of case studies to discover and
        validate the typologies, patterns and trends related to human
        trafficking.
      </p>
      <br />
      <p className={styles.sources}>
        <h3>I. Typologies / Guidance</h3>- Asia Pacific Group (APG), 2021. APG
        Yearly Typologies Report, Methods and Trends of Money Laundering and
        Terrorism Financing. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.apgml.org/methods-and-trends/page.aspx?p=8d052c1c-b9b8-45e5-9380-29d5aa129f45"
        >
          link
        </a>{' '}
        [Accessed July 2021]. <br /> - Australian Government - Fintel Alliance,
        2019. Combating The Sexual Exploitation Of Children For Financial Gain.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.austrac.gov.au/sites/default/files/2019-11/Fintel%20Alliance%20-%20Indicators%20report%20-%20Combating%20sexual%20exploitation%20of%20children.pdf"
        >
          link
        </a>
        <br />- Australian Government, 2014. Austrac Typologies And Case Studies
        Report 2014. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.austrac.gov.au/sites/default/files/2019-07/typologies-report-2014.pdf"
        >
          link
        </a>
        <br />- Caribbean FATF (CFATF), 2014. Working Group on Typologies, Human
        Trafficking and Smuggling of Migrants. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cfatf-gafic.org/documents/typologies/3564-cfatf-typologies-report-on-human-trafficking-and-the-smuggling-of-migrants-1/file"
        >
          link
        </a>{' '}
        [Accessed July 2020]. <br /> - Eastern and Southern Africa Anti-Money
        Laundering Group (ESAAMLG), 2011. Typologies project report on the
        vulnerabilities of money laundering related to trafficking in persons in
        the ESAAMLG region. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://esaamlg.org/reports/HUMAN-TRAFFICKING-Report-Mauritius-2011.pdf"
        >
          link
        </a>
        <br />- European Banks Alliance, Thomson Reuters Foundation, 2017.
        Toolkit For Tackling Human Trafficking. <br />- Financial Times, 2020.
        Deutsche Bank Fined For Jeffrey Epstein ‘Compliance Failures’. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ft.com/content/a19e6cdc-55ae-4dda-aca3-42197280e558"
        >
          link
        </a>{' '}
        [Accessed September 2020]. <br /> - Financial Times, 2020. Westpac Faces
        $900M Hit To Profit From Money-Laundering Probe. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ft.com/content/0187d0ee-ba71-460f-9f6b-5b4c5fbf3540"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - Financial Transactions and Reports
        Analysis Centre of Canada (FINTRAC), 2016. Indicators: The Laundering Of
        Illicit Proceeds From Human Trafficking For Sexual Exploitation.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fintrac-canafe.gc.ca/intel/operation/oai-hts-eng.pdf"
        >
          link
        </a>
        <br />- Financial Transactions and Reports Analysis Centre of Canada
        (FINTRAC), 2019. Transaction Reporting Requirements. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fintrac-canafe.gc.ca/guidance-directives/transaction-operation/1-eng"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - Financial Transactions and Reports
        Analysis Centre of Canada (FINTRAC), 2020. Operational Alert: Laundering
        of proceeds from online child sexual exploitation. [online] Available at
        :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fintrac-canafe.gc.ca/intel/operation/exploitation-eng.pdf"
        >
          link
        </a>
        <br />- Financial Transactions and Reports Analysis Centre of Canada
        (FINTRAC), 2021. FINTRAC-2021-OA001 Operational Alert, Updated
        Indicators: Laundering of proceeds from human trafficking for sexual
        exploitation. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fintrac-canafe.gc.ca/intel/operation/oai-hts-2021-eng.pdf"
        >
          link
        </a>
        <br />- FinCEN, US Treasury, 2014. Guidance on Recognizing Activity that
        May be Associated with Human Smuggling and Human Trafficking – Financial
        Red Flags. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/advisory/FIN-2014-A008.pdf"
        >
          link
        </a>
        <br />- FinCEN, US Treasury, 2019. Advisory on Illicit Activity
        Involving Convertible Virtual Currency. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/advisory/2019-05-10/FinCEN%20Advisory%20CVC%20FINAL%20508.pdf"
        >
          link
        </a>
        <br />- FinCEN, US Treasury, 2020. Supplemental Advisory on Identifying
        and Reporting Human Trafficking and Related Activity. [online] Available
        at :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/advisory/2020-10-15/Advisory%20Human%20Trafficking%20508%20FINAL_0.pdf"
        >
          link
        </a>
        <br />- FIU Belize, 2020. Types Of Suspicious Activities Or
        Transactions. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://fiubelize.org/types-of-suspicious-activities-or-transactions/"
        >
          link
        </a>{' '}
        [Accessed September 2020]. <br /> - Middle East and North Africa
        Financial Action Task Force (MENAFATF), 2014. Biennial Typologies
        Report. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.menafatf.org/sites/default/files/Final_Biennial_Typologies_report_EN_0.pdf"
        >
          link
        </a>
        <br />- NAICS-ASSOCIATION, 2020. High Risk/Cash Intensive NAICS
        Industries. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.naics.com/wp-content/uploads/2014/10/NAICS-ASSOCIATION-High-Risk-and-Cash-Intensive-NAICS-Codes-List.pdf"
        >
          link
        </a>
        <br />- Organization for Security and Co-operation in Europe, Office of
        the Special Representative and Co-ordinator for Combating Trafficking in
        Human Beings, 2019. Following the Money: Compendium of Resources and
        Step-by-step Guide to Financial Investigations Into Trafficking in Human
        Beings. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.osce.org/files/f/documents/f/5/438323_0.pdf"
        >
          link
        </a>
        <br />- Organization for Security and Co-operation in Europe, Office of
        the Special Representative and Co-ordinator for Combating Trafficking in
        Human Beings, 2020. Leveraging innovation to fight trafficking in human
        beings: a comprehensive analysis of technology tools. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.osce.org/files/f/documents/9/6/455206_1.pdf"
        >
          link
        </a>
        <br />- Polaris, 2017. The Typology of Modern Slavery: Defining Sex and
        Labor Trafficking in the United States. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://polarisproject.org/wp-content/uploads/2019/09/Polaris-Typology-of-Modern-Slavery-1.pdf"
        >
          link
        </a>
        <br />- Polaris, 2018. On-Ramps, Intersections, And Exit Routes: A
        Roadmap For Systems And Industries To Prevent And Disrupt Human
        Trafficking. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://polarisproject.org/wp-content/uploads/2018/08/A-Roadmap-for-Systems-and-Industries-to-Prevent-and-Disrupt-Human-Trafficking-Social-Media.pdf"
        >
          link
        </a>
        <br />- Royal United Services Institute (RUSI), 2017. Disrupting Human
        Trafficking: The Role Of Financial Institutions. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rusi.org/sites/default/files/201703_rusi_disrupting_human_trafficking.pdf"
        >
          link
        </a>
        <br />- Select Committee of Experts on the Evaluation of Anti-Money
        Laundering Measures (MONEYVAL), European Committee on Crime Problems
        (CDPC), 2005. Proceeds from trafficking in human beings and illegal
        migration/human smuggling. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rm.coe.int/select-committee-of-experts-on-the-evaluation-%20 of-anti-money-laundering/1680714f5a"
        >
          link
        </a>{' '}
        [Accessed June 2020]. <br /> - Standard Chartered Bank, 2017. Financial
        Crime Threat Assessment. <br />- The Bali Process on People Smuggling,
        Trafficking in Persons and Related Transnational Crime, 2018. Policy
        Guide On Following The Money In Trafficking In Persons Cases. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.baliprocess.net/UserFiles/baliprocess/File/Bali%20Process%20Guide%20WEB%20v01.pdf"
        >
          link
        </a>
        <br />- The Financial Action Task Force (FATF), 2011. Money Laundering
        Risks Arising From Trafficking Of Human Beings And Smuggling Of
        Migrants. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fatf-gafi.org/media/fatf/documents/reports/Trafficking%20in%20Human%20Beings%20and%20Smuggling%20of%20Migrants.pdf"
        >
          link
        </a>
        <br />- The Financial Action Task Force (FATF), APG, 2018. Financial
        Flows From Human Trafficking. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fatf-gafi.org/media/fatf/content/images/Human-Trafficking-2018.pdf"
        >
          link
        </a>
        <br />
        - The Mekong Club / Thomson Reuters Foundation, 2019. The Asia Pacific
        Banks Alliance Against Modern Slavery. <br />- The Mekong Club, 2018.
        Modern Slavery Typologies For Financial Services Providers. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://themekongclub.org/wp-content/uploads/2018/01/Typology-Repository-for-FinServ-sector.pdf"
        >
          link
        </a>
        <br />- The Mekong Club, 2021.Modern Slavery and Financial Services:
        Responding to COVID-19. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://themekongclub.org/wp-content/uploads/2020/06/Modern-Slavery-and-Financial-Services-03.pdf"
        >
          link
        </a>
        <br />- The Middle East and North Africa Financial Action Task Force
        (MENAFATF), 2021. Money Laundering Resulting from the Human Trafficking
        and Migrant Smuggling Crimes. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.menafatf.org/information-center/menafatf-publications/money-laundering-resulting-human-trafficking-and-migrant"
        >
          link
        </a>
        <br />
        - U.S. Banks Alliance, Thomson Reuters Foundation, 2018. Against Human
        Trafficking. <br />- UK Government Home Office, 2017. A Typology Of
        Modern Slavery Offences In The UK. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/652652/typology-modern-slavery-offences-horr93.pdf"
        >
          link
        </a>
        <br />- UK Independent Anti-Slavery Commissioner Office, 2020. Operation
        Fort: What Businesses Should Learn From The UK’s Largest Anti-Slavery
        Prosecution. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.antislaverycommissioner.co.uk/media/1421/iasc-operation-fort-review-june-2020.pdf"
        >
          link
        </a>
        <br />
        - UK National Crime Agency, 2014. Red Alert: Potential Indicators Of
        Slavery And Human Trafficking, A4R13670. <br />- UK National Crime
        Agency, 2020. Indicators Of Modern Slavery And Human Trafficking In The
        Accountancy Sector. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://nationalcrimeagency.gov.uk/who-we-are/publications/434-indicators-of-msht-in-the-accountancy-sector/file"
        >
          link
        </a>{' '}
        [Accessed July 2020]. <br /> - United Nations Office on Drugs and Crime,
        2020. Human Trafficking Indicators. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/pdf/HT_indicators_E_LOWRES.pdf"
        >
          link
        </a>
        <br />- The Financial Crimes Enforcement Network (FinCEN), US Treasury,
        2011. Advisory to Financial Institutions on Filing Suspicious Activity
        Reports Regarding Elder Financial Exploitation. [online] Available at:{' '}
        <a
          href="https://www.fincen.gov/sites/default/files/advisory/fin-2011-a003.pdf"
          rel="noreferrer"
          target="_blank"
        >
          link
        </a>
        <br />
        <h3> II. Research / Industry Reports</h3> - ACAMS, 2015. Human
        Trafficking Monies in the Community Banks. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.acams.org/wp-content/uploads/2015/08/Human-Trafficking-Monies-in-the-Community-Banks-N-Lake.pdf"
        >
          link
        </a>
        <br />- ACAMS, 2018. Organ Trafficking: The Unseen Form Of Human
        Trafficking. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.acamstoday.org/organ-trafficking-the-unseen-form-of-human-trafficking/"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - Bosse N., Dupont J., 2021. ACAMS Today,
        Forced Labour: Five Key Financial Risk Indicators. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.acamstoday.org/forced-labour-five-key-financial-risk-indicators/"
        >
          link
        </a>{' '}
        [Accessed August 2021]. <br /> - Brown, R., Napier, S., Smith, R. 2020.
        Australians who view live streaming of child sexual abuse: An analysis
        of financial transactions. Trends & issues in crime and criminal justice
        no. 589. Canberra: Australian Institute of Criminology. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.aic.gov.au/sites/default/files/2020-05/ti589_australians_who_view_live_streaming_of_child_sexual_abuse.pdf"
        >
          link
        </a>
        <br />- Carolin L., Lindsay A., Victor W., 2015. Sex Trafficking in the
        Tourism Industry, Journal of Tourism & Hospitality. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.longdom.org/open-access/sex-trafficking-in-the-tourism-industry-2167-0269-1000166.pdf"
        >
          link
        </a>
        <br />- Center for the Study of Democracy, 2018. Financing Of Organised
        Crime - Human Trafficking In Focus. Sofia, CSD, 2018. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://csd.bg/fileadmin/user_upload/publications_library/files/2018_12/FINOCA_2_WEB.pdf"
        >
          link
        </a>
        <br />- Congressional Research Service, 2021. International Organ
        Trafficking: In Brief. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://crsreports.congress.gov/product/pdf/R/R46996"
        >
          link
        </a>
        <br />- D’Michelle P. DuPre, Jerri Sites, 2015. Child Abuse
        Investigation Field Guide, ScienceDirect. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.sciencedirect.com/science/article/pii/B9780128023273000035"
        >
          link
        </a>
        <br />- ECPAT International, 2020. Summary Paper on Online Child Sexual
        Exploitation. Bangkok. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ecpat.org/wp-content/uploads/2021/05/ECPAT-Summary-paper-on-Online-Child-Sexual-Exploitation-2020.pdf"
        >
          link
        </a>{' '}
        [Accessed August 2021]. <br /> - ECPAT UK and the Independent
        Anti-Slavery Commissioner, 2021. Child Trafficking in the UK 2021: a
        snapshot. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.antislaverycommissioner.co.uk/media/1724/ecp05-a4-report-snapshot-21_aw7.pdf"
        >
          link
        </a>{' '}
        [Accessed December 2021]. <br /> - European Commission, 2019. Report on
        existing remote on-boarding solutions in the banking sector: Assessment
        of risks and associated mitigating controls, including interoperability
        of the remote solutions. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://ec.europa.eu/info/files/report-on-existing-remote-on-boarding-solutions-in-the-banking-sector-December2019_en"
        >
          link
        </a>{' '}
        [Accessed December 2021]. <br /> - Financial Crime News, 2019. Global
        Threat Assessment. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://thefinancialcrimenews.com/wp-content/uploads/2019/11/FCN-GTA.11.2019.Pub-Final.-2.pdf"
        >
          link
        </a>
        <br />- International Journal of Travel Medicine and Global Health,
        2021. Transplant Tourism and Organ Trafficking: Current Practices,
        Controversies and Solutions. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.ijtmgh.com/article_132577_6f0fdaa85cfc4799ece63a1ff8b68c5b.pdf"
        >
          link
        </a>
        <br />- International Justice Mission (IJM), U.S. Department of State
        Office to Monitor and Combat Trafficking in Persons (TIP Office), The
        Philippine InterAgency Council Against Trafficking (IACAT), 2020. Online
        Sexual Exploitation of Children in the Philippines: Analysis and
        Recommendations for Governments, Industry and Civil Society. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ijm.org/documents/studies/Final-Public-Full-Report-5_20_2020.pdf"
        >
          link
        </a>
        <br />- International Labour Organisation, 2021. ILO Monitor: COVID-19
        and the world of work. Eighth edition. Updated estimates and analysis.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/briefingnote/wcms_824092.pdf"
        >
          link
        </a>{' '}
        [Accessed November 2021]. <br /> - Joint Council of Europe/United
        Nations Study, 2009. Trafficking in organs, tissues and cells and
        trafficking in human beings for the purpose of the removal of organs.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rm.coe.int/16805ad1bb"
        >
          link
        </a>
        <br />- Justice and Care, The Centre for Social Justice, 2020. It Still
        Happens Here: Fighting UK Slavery in the 2020s. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.centreforsocialjustice.org.uk/core/wp-content/uploads/2020/07/It-Still-Happens-Here.pdf"
        >
          link
        </a>
        <br />- Kelly, A., Thompson, H., 2020. Silent victims: the hidden
        Romanian women exploited in the UK sex trade. The Guardian. [online]
        Available at :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.theguardian.com/global-development/2020/nov/30/silent-victims-the-hidden-romanian-women-exploited-in-the-uk-sex-trade?CMP=Share_iOSApp_Other"
        >
          link
        </a>{' '}
        [Accessed December 2020]. <br /> - Middleton, B., Antonopoulos, G. and
        Papanicolaou, G., 2019. The Financial Investigation Of Human Trafficking
        In The UK: Legal And Practical Perspectives, The Journal of Criminal
        Law. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://doi.org/10.1177/0022018319834364"
        >
          link
        </a>
        <br />- National Rapporteur on Trafficking in Human Beings, 2012. Human
        Trafficking for the purpose of the removal of organs and forced
        commercial surrogacy. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.nationaalrapporteur.nl/binaries/nationaalrapporteur/documenten/rapporten/2012/09/21/onderzoek-mensenhandel-met-het-oogmerk-van-orgaanverwijdering-en-gedwongen-commercieel-draagmoederschap-nederlands/human-trafficking-for-the-purpose-of-the-removal-of-organs-and-forced-commercial-surrogacy_tcm23-34770.pdf"
        >
          link
        </a>
        <br />- Polaris, 2021. Sexual Exploitation During the Pandemic, A
        Snapshot: April 2021. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://polarisproject.org/wp-content/uploads/2021/07/Sexual-Exploitation-During-the-Pandemic.pdf"
        >
          link
        </a>{' '}
        [Accessed December 2021]. <br /> - ProCon.org, 2018. Countries and Their
        Prostitution Policies. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://prostitution.procon.org/countries-and-their-prostitution-policies/"
        >
          link
        </a>{' '}
        [Accessed September 2020]. <br /> - Republic of the Philippines,
        Anti-Money Laundering Council, 2020. Online Sexual Exploitation Of
        Children: A crime with a global impact and an evolving transnational
        threat. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.amlc.gov.ph/images/PDFs/2020%20AUG%20AMLC%20OSEC%20AN%20EMERGING%20RISK%20AMID%20THE%20COVID19%20PANDEMIC.pdf"
        >
          link
        </a>
        <br />- Royal United Services Institute (RUSI), 2018. Leaning In:
        Advancing The Role Of Finance Against Modern Slavery. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rusi.org/sites/default/files/20181212_leaning_in_web.pdf"
        >
          link
        </a>
        <br />- Royal United Services Institute (RUSI), 2019. Deep Impact?
        Refocusing The Anti-Money Laundering Model On Evidence And Outcomes.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://rusi.org/sites/default/files/20191011_deep_impact_web.pdf"
        >
          link
        </a>
        <br />- Financial Crime News, 2020. Why Illegal Trafficking in Organs is
        growing fast... but few are talking about it... [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://thefinancialcrimenews.com/why-illegal-trafficking-in-organs-is-growing-fastbut-few-are-talking-about-itby-steve-farrer/"
        >
          link
        </a>{' '}
        [Accessed March 2022] <br />- The International Centre for Missing &
        Exploited Children, Standard Chartered Bank, 2021. Cryptocurrency and
        the Trade of Online Child Sexual Abuse Material. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cdn.icmec.org/wp-content/uploads/2021/03/Cryptocurrency-and-the-Trade-of-Online-Child-Sexual-Abuse-Material_03.17.21-publish-1.pdf"
        >
          link
        </a>{' '}
        [Accessed August 2021]. <br /> - The Modern Slavery and Human Rights
        Policy and Evidence Centre, led by the Bingham Centre for the Rule of
        Law, 2021. Forced labour in the Malaysian medical gloves supply chain
        during the Covid-19 pandemic, Research Summary. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://modernslaverypec.org/assets/downloads/Malaysia-research-summary.pdf"
        >
          link
        </a>{' '}
        [Accessed November 2021]. <br /> - The WePROTECT Global Alliance, 2019.
        Global Threat Assessment 2019. Working together to end the sexual
        exploitation of children online. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.weprotect.org/wp-content/uploads/WPGA-Global-Threat-Assessment-2019.pdf"
        >
          link
        </a>
        <br />- Themis International Services, the UK Independent Anti-Slavery
        Commissioner, the TRIBE Freedom Foundation, 2021. Preventing Modern
        Slavery and Human Trafficking: An Agenda for Action across the Financial
        Services Sector. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://themisservices.co.uk/msht"
        >
          link
        </a>
        <br /> [Accessed February 2021]. <br /> - THORN, 2018. Survivor
        Insights: The Role of Technology in Domestic Minor Sex Trafficking.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.thorn.org/wp-content/uploads/2019/12/Thorn_Survivor_Insights_090519.pdf"
        >
          link
        </a>{' '}
        [Accessed July 2021]. <br /> - Transparency International UK, 2020.
        Fighting Corruption Worldwide. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.transparency.org.uk/publications/fighting-corruption-worldwide-2020"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - United Nations Children’s Fund, 2020.
        Action to end child sexual abuse and exploitation: A review of the
        evidence, UNICEF, New York. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unicef.org/media/89096/file/CSAE-Report-v2.pdf"
        >
          link
        </a>{' '}
        [Accessed July 2021]. <br /> - United Nations Office on Drugs and Crime
        (UNODC), 2015. Study on the Effects of New Information Technologies on
        the Abuse and Exploitation of Children. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/Cybercrime/Study_on_the_Effects.pdf"
        >
          link
        </a>
        <br />- United Nations Office on Drugs and Crime (UNODC), 2021. A global
        study of emerging evidence, The effects of the COVID-19 pandemic on
        trafficking in persons and responses to the challenges. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/human-trafficking/2021/The_effects_of_the_COVID-19_pandemic_on_trafficking_in_persons.pdf"
        >
          link
        </a>{' '}
        [Accessed August 2021]. <br /> - United Nations Office on Drugs and
        Crime (UNODC), 2021. Compendium of Promising Practices on Public-Private
        Partnerships to prevent and counter trafficking in persons. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/NGO/PPP/UNODC-PPP-Interactive.pdf"
        >
          link
        </a>{' '}
        [Accessed November 2021]. <br /> - United Nations Office on Drugs and
        Crime, 2015. Trafficking In Persons For The Purpose Of Organ Removal.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/human-trafficking/2015/UNODC_Assessment_Toolkit_TIP_for_the_Purpose_of_Organ_Removal.pdf"
        >
          link
        </a>
        <br />- United Nations Office on Drugs and Crime, 2018. Global Report On
        Trafficking In Persons 2018. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/data-and-analysis/glotip/2018/GLOTiP_2018_BOOK_web_small.pdf"
        >
          link
        </a>
        <br />- United Nations Office on Drugs and Crime, 2020. Global Report On
        Trafficking In Persons 2020. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unodc.org/documents/data-and-analysis/tip/2021/GLOTiP_2020_15jan_web.pdf"
        >
          link
        </a>
        <br />- United Nations University Centre for Policy Research, 2017. Can
        We Sustain Peace By Fighting Human Trafficking In Conflict? Lessons From
        Libya. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cpr.unu.edu/can-we-sustain-peace-by-fighting-human-trafficking-in-conflict-lessons-from-libya.html"
        >
          link
        </a>{' '}
        [Accessed July 2020]. <br /> - United Nations University Centre for
        Policy Research, New York, September 2019. Unlocking Potential: A
        Blueprint For Mobilizing Finance Against Slavery And Trafficking. Final
        Report of the Liechtenstein Initiative’s Financial Sector Commission on
        Modern Slavery and Human Trafficking. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fastinitiative.org/wp-content/uploads/Blueprint-DIGITAL-3.pdf"
        >
          link
        </a>
        <br />- University of Nottingham and the Independent Anti-Slavery
        Commissioner, 2018. Modern Slavery, Environmental Destruction And
        Climate Change. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.antislaverycommissioner.co.uk/media/1241/fisheries-field-forests-factories.pdf"
        >
          link
        </a>
        <br />- US Department of State, 2020. Trafficking In Persons Report
        2021. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.state.gov/wp-content/uploads/2021/09/TIPR-GPA-upload-07222021.pdf"
        >
          link
        </a>
        <br />- Verisk Maplecroft, 2020. Human Rights Outlook 2020. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.maplecroft.com/insights/analysis/human-rights-outlook-2020/"
        >
          link
        </a>{' '}
        [Accessed September 2020]. <br /> - World Bank Group, 2020. The State Of
        Identification Systems In Africa : A Synthesis Of Country Assessments.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://openknowledge.worldbank.org/handle/10986/26504"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - World Economic Forum, 2015. Hedging
        Risk By Combating Human Trafficking: Insights From The Private Sector.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www3.weforum.org/docs/WEF_Human_Trafficking_Report_2015.pdf"
        >
          link
        </a>
        <br />- Arizona Elder Abuse Coalition, Financial Exploitation of the
        Elderly - How the Financial Institutions Can Help. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.azag.gov/sites/default/files/publications/2018-06/FinancialExploitationoftheElderly.pdf"
        >
          link
        </a>
        <br />- BITS, Financial Services Roundtable, 2012. Protecting the
        Elderly from Financial Fraud and Exploitation. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://acl.gov/sites/default/files/programs/2016-09/Smocer_White_Paper.pdf"
        >
          link
        </a>
        <br />- Federal Trade Commission, Consumer Advice, 2021. Gift Card
        Scams. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://consumer.ftc.gov/articles/gift-card-scams#what%20the%20scam%20looks%20like"
        >
          link
        </a>{' '}
        [Accessed May 2022]
        <br />- Federal Trade Commission, Protecting Older Consumers 2020-2021,
        2021. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ftc.gov/system/files/documents/reports/protecting-older-consumers-2020-2021-report-federal-trade-commission/protecting-older-consumers-report-508.pdf"
        >
          link
        </a>
        <br />- The Commonwealth of Massachusetts, 2017. Preventing Elder
        Financial Exploitation - Employee Training Manual for Financial
        Institutions. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.mass.gov/doc/preventing-elder-financial-exploitation-employee-training-manual-for-financial-institutions/download"
        >
          link
        </a>
        <br />
        <h3>III. Regulations / Standards / Enforcement</h3> - College of
        Policing, 2021. Violence against women and girls toolkit: Sexual
        exploitation or abuse. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.college.police.uk/guidance/violence-against-women-and-girls-toolkit/sexual-exploitation-or-abuse"
        >
          link
        </a>
        <br />- Directive (EU) 2018/1673 of the European Parliament and of the
        Council of 23 October 2018 on combating money laundering by criminal
        law. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://data.europa.eu/eli/dir/2018/1673/oj"
        >
          link
        </a>
        <br />- Directive (EU) 2018/843 of the European Parliament and of the
        Council of 30 May 2018 amending Directive (EU) 2015/849 on the
        prevention of the use of the financial system for the purposes of money
        laundering or terrorist financing, and amending Directives 2009/138/EC
        and 2013/36/EU. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://data.europa.eu/eli/dir/2018/843/oj"
        >
          link
        </a>
        <br />- Directive 2011/36/EU of the European Parliament and of the
        Council of 5 April 2011 on preventing and combating trafficking in human
        beings and protecting its victims, and replacing Council Framework
        Decision 2002/629/ JHA. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://data.europa.eu/eli/dir/2011/36/oj"
        >
          link
        </a>
        <br />- European Banking Authority, 2021. Consultation Paper on Draft
        Guidelines on the Use of Remote Customer Onboarding Solutions. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.eba.europa.eu/sites/default/documents/files/document_library/Publications/Consultations/2022/Consultation%20on%20draft%20Guidelines%20on%20the%20use%20of%20remote%20customer%20onboarding%20solutions/1025218/CP%20on%20draft%20GLs%20on%20remote%20customer%20onboarding.pdf"
        >
          link
        </a>
        <br />- European Commission, 2020. Third report on the progress made in
        the fight against trafficking in human beings (2020) as required under
        Article 20 of Directive 2011/36/EU on preventing and combating
        trafficking in human beings and protecting its victims. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:52020SC0226&rid=1"
        >
          link
        </a>
        <br />- European Parliament, Policy Department, 2015. Trafficking in
        human organs. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europarl.europa.eu/RegData/etudes/STUD/2015/549055/EXPO_STU(2015)549055_EN.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 2015. The Trafficking in Human Beings Financial
        Business Model. Assessing the Current State of Knowledge. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/sites/default/files/documents/europol_thb_finacial_business_model_2015.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 2020. Exploiting Isolation: Offenders and victims of
        online child sexual abuse during the COVID-19 pandemic. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/cms/sites/default/files/documents/europol_covid_report-cse_jun2020v.3_0.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 2020. Beyond the pandemic how COVID-19 will shape the
        serious and organised crime landscape in the EU. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/sites/default/files/documents/report_beyond_the_pandemic.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 2021. Internet Organised Crime Threat Assessment 2021.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/cms/sites/default/files/documents/internet_organised_crime_threat_assessment_iocta_2021.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 2021. European Union serious and organised crime threat
        assessment, A corrupting influence: the infiltration and undermining of
        Europe's economy and society by organised crime, Publications Office of
        the European Union, Luxembourg. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/activities-services/main-reports/european-union-serious-and-organised-crime-threat-assessment"
        >
          link
        </a>
        <br />- Financial Action Task Force (FATF), 2012-2019. International
        Standards on Combating Money Laundering and the Financing of Terrorism &
        Proliferation, The FATF Recommendations. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.fatf-gafi.org/media/fatf/documents/recommendations/pdfs/FATF%20Recommendations%202012.pdf"
        >
          link
        </a>
        <br />- Government of Australia, 2018. Modern Slavery Act 2018, No. 153
        2018, C2018A00153, 10 December 2018, entered into force 1 January 2019.
        [online] Available from
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.legislation.gov.au/Details/C2018A00153"
        >
          link
        </a>
        <br />- HM Government, UK Finance, 2019. Economic Crime Plan 2019-2022.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/816215/2019-22_Economic_Crime_Plan.pdf"
        >
          link
        </a>
        <br />- INTERPOL, 2018. Project ENACT. Towards a Global Indicator on
        Unidentified Victims in Child Sexual Exploitation Material. Technical
        report. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.interpol.int/en/content/download/9365/file/Technical%20report%20-%20Towards%20a%20Global%20Indicator%20on%20Unidentified%20Victims%20in%20Child%20Sexual%20Exploitation%20Material.%20February%202018.pdf"
        >
          link
        </a>
        <br />- INTERPOL, 2020. Project ENACT. Using criminal analysis to
        enhance Africa’s response to transnational organized crime. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.interpol.int/en/How-we-work/Criminal-intelligence-analysis/Project-ENACT"
        >
          link
        </a>
        <br />- INTERPOL, 2021. Annual Report 2020. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.interpol.int/content/download/16499/file/Annual%20report%202020_EN_i.pdf"
        >
          link
        </a>
        <br />- INTERPOL, Analytical Report, 2021. Trafficking of Human Beings
        for the purpose of Organ Removal in North and West Africa. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.interpol.int/en/content/download/16690/file/2021%2009%2027%20THBOR%20ENGLISH%20Public%20Version%20FINAL.pdf"
        >
          link
        </a>
        <br />- The African Union Commission/United Nations Economic Commission
        for Africa (AUC/ECA) Conference of African Ministers of Finance,
        Planning and Economic Development, 2020. Report of the High Level Panel
        on Illicit Financial Flows from Africa. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.uneca.org/sites/default/files/PublicationFiles/iff_main_report_26feb_en.pdf"
        >
          link
        </a>
        <br />- The Egmont Group, 2019. Professional Money Laundering
        Facilitators, Public Bulletin. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://egmontgroup.org/sites/default/files/filedepot/external/20190701%20-%20IEWG%20Professional%20ML%20Networks%20Bulletin%20-%20final.v.pdf"
        >
          link
        </a>
        <br />- The Egmont Group, 2020. Combatting Child Sexual Abuse and
        Exploitation, Public Bulletin. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://egmontgroup.org/sites/default/files/filedepot/20200901_CSAE%20Public%20Bulletin.pdf"
        >
          link
        </a>
        <br />- The End Banking for Human Traffickers Act of 2019, H.R. 295,
        introduced 8 January 2019. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.congress.gov/bill/116th-congress/house-bill/295/text"
        >
          link
        </a>
        <br />- The Financial Action Task Force (FATF), 2020. Money Laundering
        and Terrorist Financing Red Flag Indicators Associated with Virtual
        Assets. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.fatf-gafi.org/publications/fatfrecommendations/documents/Virtual-Assets-Red-Flag-Indicators.html"
        >
          link
        </a>
        <br />- The Financial Action Task Force (FATF), 2020. Update:
        COVID-19-related Money Laundering and Terrorist Financing. [online]
        Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fatf-gafi.org/media/fatf/documents/Update-COVID-19-Related-Money-Laundering-and-Terrorist-Financing-Risks.pdf"
        >
          link
        </a>
        <br />- The Financial Crimes Enforcement Network (FinCEN), US Treasury,
        2021. FinCEN Anti-Money Laundering and Countering the Financing of
        Terrorism National Priorities. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/shared/AML_CFT%20Priorities%20(June%2030%2C%202021).pdf"
        >
          link
        </a>
        <br />- The Financial Crimes Enforcement Network (FinCEN), US Treasury,
        2021. FinCEN Notice FIN-2021-NTC3, FinCEN Calls Attention to Online
        Child Sexual Exploitation Crimes. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/shared/FinCEN%20OCSE%20Notice%20508C.pdf"
        >
          link
        </a>
        <br />- The New York State Department of Financial Services, 2020.
        Consent Order Under New York Banking Law §§ 39 and 44. New York, 06 July
        2020. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.dfs.ny.gov/system/files/documents/2020/07/ea20200706_deutsche_bank_consent_order.pdf"
        >
          link
        </a>
        <br />- The UK Modern Slavery Act 2015, in force 31 July 2015, S.I.
        2005/1476, 2015. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://www.legislation.gov.uk/ukpga/2015/30/contents"
        >
          link
        </a>
        <br />- UK Government Home Office, 2020. Interim Code of Practice on
        Online Child Sexual Exploitation and Abuse. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/944034/1704__HO__INTERIM_CODE_OF_PRACTICE_CSEA_v.2.1_14-12-2020.pdf"
        >
          link
        </a>
        <br />- UK Government Home Office, 2020. UK Government Modern Slavery
        Statement. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/875800/UK_Government_Modern_Slavery_Statement.pdf"
        >
          link
        </a>
        <br />- UK Independent Anti-Slavery Commissioner Office, 2020.
        Independent Anti-Slavery Commissioner Annual Report 2019-2020. [online]
        Available at :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/918675/CCS207_CCS0520602790-001_IASC_Annual_Report_2019-2020_Web_Accessible_final.pdf"
        >
          link
        </a>
        <br />- UK Independent Anti-Slavery Commissioner Office, 2021. The role
        of the financial sector in eradicating modern slavery: CEOs respond to
        the Independent Anti-Slavery Commissioner. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.antislaverycommissioner.co.uk/media/1672/iasc-report-sep-2021-the-role-of-the-financial-services-sector-in-eradicating-modern-slavery.pdf"
        >
          link
        </a>
        <br />- UK National Crime Agency, 2021. National Strategic Assessment Of
        Serious And Organised Crime 2021. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.nationalcrimeagency.gov.uk/who-we-are/publications/533-national-strategic-assessment-of-serious-and-organised-crime-2021/file"
        >
          link
        </a>
        <br />- UN Guiding Principles on Business and Human Rights: Implementing
        the United Nations Protect, Respect and Remedy Framework. New
        York/Geneva, OCHRC, 2011. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ohchr.org/Documents/Publications/ GuidingPrinciplesBusinessHR_EN.pdf"
        >
          link
        </a>
        <br />- United Nations General Assembly, 2015. UN Resolution adopted by
        the General Assembly on 25 September 2015, Transforming our world: the
        2030 Agenda for Sustainable Development. [online] Available from:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.unfpa.org/sites/default/files/resource-pdf/Resolution_A_RES_70_1_EN.pdf"
        >
          link
        </a>
        <br />- EUROPOL, 13 arrested for tricking elderly with love in Italy,
        2021. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.europol.europa.eu/media-press/newsroom/news/13-arrested-in-italy-for-tricking-elderly-love?utm_medium=email&_hsmi=213939001&_hsenc=p2ANqtz-9IYYup7acM-5uflhP2xNy9goKGbFzyUpTVuT3m99k57J_kM0A05srvTL1m2Sli9vpY26i9qGNMIOHPiRPJLxQQzvrDz8wPKY5TKK0nErs289kcvJY&utm_content=213939001&utm_source=hs_email"
        >
          link
        </a>{' '}
        [Accessed May 2022]
        <br />- The Financial Crimes Enforcement Network (FinCEN), US Treasury,
        2019. Financial Trend Analysis: Elders Face Increased Financial Threat
        from Domestic and Foreign Actors. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fincen.gov/sites/default/files/shared/FinCEN%20Financial%20Trend%20Analysis%20Elders_FINAL%20508.pdf"
        >
          link
        </a>{' '}
        <br />- U.S. Department of Justice, 2018. Annual Report to Congress on
        Department of Justice Activities to Combat Elder Fraud and Abuse.
        [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.justice.gov/file/1443096/download"
        >
          link
        </a>{' '}
        <br />
        IV. Statistics / Data <br />- Alliance 8.7, 2017. Global Estimates Of
        Modern Slavery. Forced Labour and Forced Marriage. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_575479.pdf"
        >
          link
        </a>
        <br />- Anti-Human Trafficking Intelligence Initiative, 2021. High-Risk
        Database Due-Diligence Tool. 123 Babson College Initiative on Human
        Trafficking / Global Business Coalition Against Human Trafficking /
        Global Initiative Against Transnational Organized Crime / International
        Labour Organization / International Organization for Migration / United
        Nations Global Compact, 2018. The Interactive Map for Business of
        Anti-Human Trafficking Organisations. [online] Available at:{' '}
        <a target="_blank" rel="noreferrer" href="www.modernslaverymap.org">
          link
        </a>
        <br /> [Accessed February 2021]. <br /> - BankTrack, 2019. BankTrack
        2019 Human Rights Benchmark. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.banktrack.org/download/the_banktrack_human_rights_benchmark_2019/191125humanrightsbenchmark_1.pdf"
        >
          link
        </a>
        <br />- Delta 8.7. 2019. Data Dashboards - Delta 8.7. [online] Available
        at :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://delta87.org/dashboards-landing/data-dashboards/"
        >
          link
        </a>{' '}
        [Accessed February 2021]. <br /> - International Labour Organization,
        2017. Global Estimates Of Modern Slavery: Forced Labour And Forced
        Marriage. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ilo.org/wcmsp5/groups/public/---dgreports/---dcomm/documents/publication/wcms_575479.pdf"
        >
          link
        </a>
        <br />- International Organization for Migration, 2017. Migrant
        Vulnerability to Human Trafficking and Exploitation: Evidence from the
        Central and Eastern Mediterranean Migration Routes. [online] Available
        at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://migration.iom.int/docs/migrant_vulnerability_to_human_trafficking_and_exploitation_november_2017.pdf"
        >
          link
        </a>
        <br />- International Organization for Migration, 2020. Data Innovation
        Directory. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://migrationdataportal.org/data-innovation"
        >
          link
        </a>{' '}
        [Accessed February 2021]. <br /> - International Organization for
        Migration, 2020. Displacement Tracking Matrix | DTM. [online] Available
        at:{' '}
        <a target="_blank" rel="noreferrer" href="https://dtm.iom.int/">
          link
        </a>
        <br /> [Accessed February 2021]. <br /> - International Organization for
        Migration, 2020. Global Migration Data Portal. [online] Available at :
        <a
          target="_blank"
          rel="noreferrer"
          href="https://migrationdataportal.org/data?i=stock_abs_&t=2020"
        >
          link
        </a>{' '}
        [Accessed February 2021]. <br /> - Internet Watch Foundation (IWF),
        2021. The Annual Report 2020. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://annualreport2020.iwf.org.uk/"
        >
          link
        </a>
        <br /> [Accessed August 2021]. <br /> - Liberty Shared, 2020. Analysis
        Of Perpetrators Of Human Trafficking. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://libertyshared.org/nht-dashboard"
        >
          link
        </a>
        <br /> [Accessed February 2021]. <br /> - National Society for the
        Prevention of Cruelty to Children (NSPCC), 2021. Statistics briefing:
        child sexual abuse. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://learning.nspcc.org.uk/media/1710/statistics-briefing-child-sexual-abuse.pdf"
        >
          link
        </a>{' '}
        [Accessed August 2021]. <br /> - Polaris, 2019. The U.S. National Human
        Trafficking Hotline 2019 Data Report. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://polarisproject.org/wp-content/uploads/2019/09/Polaris-2019-US-National-Human-Trafficking-Hotline-Data-Report.pdf"
        >
          link
        </a>
        <br />- The Counter Trafficking Data Collaborative (CTDC), 2020. The
        Global Dataset 14 Apr 2020. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.ctdatacollaborative.org/dataset/resource/511adcb7-b1a2-4cc7-bf2f-0960d43a49cc"
        >
          link
        </a>{' '}
        [Accessed February 2021]. <br /> - The Minderoo Foundation's Walk Free
        Initiative, 2018. GLOBAL SLAVERY INDEX 2018. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.globalslaveryindex.org/resources/downloads/"
        >
          link
        </a>{' '}
        [Accessed August 2020]. <br /> - The Minderoo Foundation's Walk Free
        Initiative, 2019. Measurement, Action, Freedom. An independent
        assessment of government progress towards achieving UN Sustainable
        Development Goal 8.7. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://cdn.globalslaveryindex.org/2019-content/uploads/2019/07/17123602/walk.free_.MAF_190717_FNL_DIGITAL-P.pdf"
        >
          link
        </a>
        <br />- Traffik Analysis Hub, 2021. Human Trafficking Data Insight. 139
        United Nations Office on Drugs and Crime. The Human Trafficking
        Knowledge Portal, Case Law Database. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://sherloc.unodc.org/cld/v3/htms/cldb/index.html?lng=en"
        >
          link
        </a>{' '}
        [Accessed February 2021]. <br />- CFPF (Consumer Financial Protection
        Bureau), 2019. Suspicious Activity Reports on Elder Financial
        Exploitation: Issues and Trends. [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://files.consumerfinance.gov/f/documents/cfpb_suspicious-activity-reports-elder-financial-exploitation_report.pdf"
        >
          link
        </a>
        <br />- Consumer Reports, 2015. Financial Elder Abuse Costs $3 Billion a
        Year. Or Is It $36 Billion? [online] Available at:{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.consumerreports.org/cro/consumer-protection/financial-elder-abuse-costs--3-billion-----or-is-it--30-billion-"
        >
          link
        </a>{' '}
        [Accessed May 2022].
        <br />
      </p>
      {/*<ul>
        <li></li>
        <li>
          II. Research / Industry Reports
          <ul>
            <li>
              39 ACAMS, 2015. Human Trafficking Monies in the Community Banks.
              [online] Available
              at:{' '} &#60;http://www.acams.org/wp-content/uploads/2015/08/Human-Trafficking-Monies-in-the-Community-Banks-N-Lake.pdf&#62;
            </li>
            <li>
              40 ACAMS, 2018. Organ Trafficking: The Unseen Form Of Human
              Trafficking. [online] Available at:{' '} 
              &#60;https://www.acamstoday.org/organ-trafficking-the-unseen-form-of-human-trafficking/&#62;
              [Accessed August 2020]. <br />
            </li>
          </ul>
        </li>
      </ul>*/}
    </div>
  );
};

export default Sources;
