import React from 'react';
import styles from './highRiskOverview.module.scss';
type Props = {};

const DataSources = (props: Props) => {
  return (
    <>
      <p className={styles.paragraph}>
        The main sources of data on human trafficking globally are based on
        information provided by identified victims. These are usually collected
        by a range of different actors, including law enforcement and
        non-governmental organizations (NGOs) providing protection and
        assistance to victims.
      </p>
      <p className={styles.paragraph}>
        Several UN agencies and international NGOs have collaborated to produce
        data sources. The International Organization for Migration (IOM) has
        been providing assistance to victims since the mid-1990s (around 8,000
        victims per year) and has developed the largest database of trafficking
        victim case data in the world, with information on over 55,000
        individual cases. In 2017, IOM made its data publicly available through
        the Counter Trafficking Data Collaborative (CTDC), along with combined
        data from other leading counter-trafficking organisations with
        significant case-level datasets. The resulting dataset is the largest of
        its kind globally, with information on over 108,000 individual cases of
        human trafficking, representing 175 different nationalities identified
        in 164 countries of exploitation. This is the main source for data used
        in the RedFlag Accelerator Typologies.
      </p>
      <p className={styles.paragraph}>
        To ensure that the RedFlag Accelerator Typologies reflects the most
        recent human trafficking trends, we also consult and incorporate the
        data and intelligence provided by our partner, Traffik Analysis Hub (TA
        Hub). TA Hub solution supports joint analysis of large AI-generated
        human trafficking data sets. It enables transformation of new insights
        into patterns and hotspots of trafficking incidents, and significantly
        contributes to overall picture of human trafficking modus operandi.
      </p>
      <p className={styles.paragraph}>
        To validate our findings and ensure integrity we also examine the
        aggregated data from the UNODC Global Report on Trafficking in Persons,
        Trafficking Database and the annual US Trafficking in Persons (TIP)
        Report. The estimated data from the Global Estimates of Modern Slavery
        produced by the International Labour Organisation (ILO) alongside the
        consolidated data available from the Liberty Shared interactive human
        trafficking dashboard are also instrumental for analysis and balanced
        assessment of the high-risk territories and corridors.
      </p>
    </>
  );
};

export default DataSources;
