import { ReactNode } from 'react';
import { ReactComponent as IconArrow } from 'assets/icons/icon-arrow.svg';
import styles from './sidebarTypology.module.scss';

type Props = {
  children: ReactNode;
};

const SidebarTypology = ({ children }: Props) => {
  return (
    <nav aria-label="typology navigation" className={styles.navigation}>
      <h5>
        Typology Navigation <IconArrow />
      </h5>
      {children}
    </nav>
  );
};

export default SidebarTypology;
