import React, { useState } from 'react';
import Overview from './Overview';
import HumanCrime from './HumanCrime';
import Sources from './Sources';
import styles from './about.module.scss';

type Props = {};

const About = (props: Props) => {
  const [showTab, setShowTab] = useState<
    'overview' | 'human-crime' | 'sources'
  >('overview');
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.description}>
          {showTab === 'overview' && <Overview />}
          {showTab === 'human-crime' && <HumanCrime />}
          {showTab === 'sources' && <Sources />}
        </div>
        <ul className={styles.tabs}>
          <li
            className={`${styles.tab} ${
              showTab === 'overview' ? styles.active : ''
            }`}
            onClick={() => setShowTab('overview')}
          >
            Overview
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'human-crime' ? styles.active : ''
            }`}
            onClick={() => setShowTab('human-crime')}
          >
            Human Crime
          </li>
          <li
            className={`${styles.tab} ${
              showTab === 'sources' ? styles.active : ''
            }`}
            onClick={() => setShowTab('sources')}
          >
            Sources
          </li>
        </ul>
      </div>
    </section>
  );
};

export default About;
