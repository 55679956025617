// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/hero-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero_wrapper__wrkfb {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  padding: 7rem 2rem;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.hero_content__WYhDc {\n  color: var(--color-white);\n  max-width: 70rem;\n  margin: 0 auto;\n  font-family: var(--font-display);\n  text-align: left;\n}\n\n.hero_heading__primary__9aWQk {\n  font-size: 4.4rem;\n  color: inherit;\n}\n.hero_heading__secondary__t\\+HFD {\n  font-size: 2.4rem;\n  color: inherit;\n}\n\n.hero_description__-IwsZ {\n  color: var(--text-description);\n  font-size: 1.6rem;\n  line-height: 30px;\n  font-family: var(--font-main);\n  margin-top: 2rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Hero/hero.module.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,gBAAA;EACA,cAAA;EACA,gCAAA;EACA,gBAAA;AACF;;AAGE;EACE,iBAAA;EACA,cAAA;AAAJ;AAGE;EACE,iBAAA;EACA,cAAA;AADJ;;AAKA;EACE,8BAAA;EACA,iBAAA;EACA,iBAAA;EACA,6BAAA;EACA,gBAAA;AAFF","sourcesContent":[".wrapper {\n  background-image: url('../../assets/hero-bg.png');\n  padding: 7rem 2rem;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.content {\n  color: var(--color-white);\n  max-width: 70rem;\n  margin: 0 auto;\n  font-family: var(--font-display);\n  text-align: left;\n}\n\n.heading {\n  &__primary {\n    font-size: 4.4rem;\n    color: inherit;\n  }\n\n  &__secondary {\n    font-size: 2.4rem;\n    color: inherit;\n  }\n}\n\n.description {\n  color: var(--text-description);\n  font-size: 1.6rem;\n  line-height: 30px;\n  font-family: var(--font-main);\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "hero_wrapper__wrkfb",
	"content": "hero_content__WYhDc",
	"heading__primary": "hero_heading__primary__9aWQk",
	"heading__secondary": "hero_heading__secondary__t+HFD",
	"description": "hero_description__-IwsZ"
};
export default ___CSS_LOADER_EXPORT___;
