import React from 'react';
import styles from './typologyGuide.module.scss';

type Props = {};

const Guide = (props: Props) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Typologies Guide</h2>
      <p className={styles.description}>
        RedFlag Accelerator Typologies allow for a targeted, risk-based client
        assessment, applicable across different business models and geographies,
        and suitable for various types of clients, products and services.
      </p>

      <h3 className={styles.subHeading}>Red Flag</h3>
      <p className={styles.description}>
        an indicator of any undesirable characteristic or behaviour that can be
        detected in data, suggesting that there is a potential threat relating
        to criminal activity.
      </p>

      <h3 className={styles.subHeading}>Scenario</h3>
      <p className={styles.description}>
        a combination of Red Flags revealing a repeated persona-based pattern of
        elements (e.g., characteristics, behaviours etc.) in data that is
        matching a typology.
      </p>

      <h3 className={styles.subHeading}>Typology</h3>
      <p className={styles.description}>
        a combination of Scenarios in a general group defining a particular
        class of criminality (e.g., sex trafficking, forced labour, child sexual
        exploitation etc.) that are sufficiently homogeneous to be treated as a
        type.
      </p>

      <p className={styles.note}>
        Current version provides a selection of 7 key typologies that are broken
        down in 31 scenarios, which can be adopted to accelerate the detection
        of suspicious and potentially criminal activities.
      </p>

      <p className={styles.note}>
        Each persona-driven scenario contains a list of relevant, weighted red
        flags, and is further complemented with a description summarising key
        characteristics and behaviours.
      </p>
    </div>
  );
};

export default Guide;
