import styles from './events.module.scss';

type Props = {
  data: {
    image_url: string;
    title: string;
    date: string;
    description: string;
    tags: string[];
    url: string;
  };
};

const EventArticle = ({ data }: Props) => {
  return (
    <article className={styles.article}>
      <figure className={styles['article__image-wrapper']}>
        <a
          target="_blank"
          rel="noreferrer"
          href={data.url}
          className={styles.article__link}
        >
          <img
            className={styles.article__image}
            src={data.image_url}
            alt={data.title}
          />
        </a>
      </figure>
      <div className={styles.article__content}>
        <div className={styles.article__content__info}>
          <p className={styles.article__content__info__topics}>
            {data.tags.join(', ')}
          </p>
          <p className={styles.article__content__info__date}>{data.date}</p>
        </div>
        <h1 className={styles.article__content__title}>
          <a target="_blank" rel="noreferrer" href={data.url}>
            {data.title}
          </a>
        </h1>
        <p className={styles.article__content__description}>
          {data.description.slice(0, 160) + '...'}
        </p>
        <a
          className={styles.article__content__read}
          target="_blank"
          rel="noreferrer"
          href={data.url}
        >
          Read more
        </a>
      </div>
    </article>
  );
};

export default EventArticle;
