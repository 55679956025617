import { useParams } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs';
import ScenarioList from 'components/ScenarioList';
import { createMarkup } from 'helpers/createMarkup';
import styles from '../typologies.module.scss';

interface IndicatorType {
  id: number;
  name: string;
  description: string;
  strength: string;
  url: string;
}

interface ScenarioType {
  id: number;
  name: string;
  description: string;
  url: string;
  indicators: IndicatorType[];
}

type Props = {
  clickedScenario: ScenarioType;
  indicatorClickHandler: Function;
};

const Scenario = ({ clickedScenario, indicatorClickHandler }: Props) => {
  const { typologyId } = useParams();

  const crumbData = [
    {
      title: `Typology ${typologyId}`,
      url: `/typologies/typology=${typologyId}`,
    },
    {
      title: `Scenario ${clickedScenario?.id}`,
      url: '#',
    },
  ];

  return clickedScenario ? (
    <section className={styles.typology__wrapper}>
      <Breadcrumbs crumbData={crumbData} />
      <h2>{clickedScenario.name}</h2>
      <h3>Description</h3>
      <div className={styles.typology__content}>
        <div
          className={styles.typology__content__description}
          dangerouslySetInnerHTML={createMarkup(clickedScenario.description)}
        ></div>
        {clickedScenario.indicators && (
          <ScenarioList
            title="Indicators"
            typologyData={[]}
            scenarioListData={clickedScenario.indicators}
            selectionMode={false}
            selectionList={[]}
            scenarioClickHandler={indicatorClickHandler}
          />
        )}
      </div>
    </section>
  ) : null;
};

export default Scenario;
